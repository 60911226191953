import { useQuery } from '@tanstack/react-query';

import { oprApi } from '~/shared/services/api';

type GetEmployeeLastestRateCommentAPIResponse = {
  comment: string;
};

function getEmployeeLastestRateComment(id: string) {
  return oprApi.get<GetEmployeeLastestRateCommentAPIResponse>(
    `employees/${id}/latest-rate-comment`
  );
}

export const GET_EMPLOYEE_LASTEST_RATE_COMMENT_QUERY_KEY =
  'useGetEmployeeLastestRateComment';

type UseGetEmployeeLastestRateCommentOptions = {
  enabled?: boolean;
};

export function useGetEmployeeLastestRateComment(
  employeeId: string,
  { enabled = true }: UseGetEmployeeLastestRateCommentOptions = {}
) {
  return useQuery({
    queryKey: [GET_EMPLOYEE_LASTEST_RATE_COMMENT_QUERY_KEY, employeeId],
    queryFn: () => getEmployeeLastestRateComment(employeeId),
    select: data => data.data,
    enabled,
  });
}
