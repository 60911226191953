import { useMutation } from '@tanstack/react-query';

import { translationApi } from '~/shared/services/api';

async function getTranslationFile() {
  const response = await translationApi.get('language/file/download', {
    responseType: 'blob',
  });
  return response.data;
}

type UseGetTranslationFileOptions = {
  onSuccess?: (data: Blob) => void;
  onError?: (error: Error) => void;
};

export function useGetTranslationFile({
  onError,
  onSuccess,
}: UseGetTranslationFileOptions = {}) {
  return useMutation({
    mutationFn: getTranslationFile,
    onSuccess(data) {
      onSuccess?.(data as unknown as Blob);
    },
    onError,
  });
}
