import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import { translationApi } from '~/shared/services/api';

function postTranslationFile(formData: FormData) {
  return translationApi.post('language/file/upload', formData);
}

type UsePostTranslationFileOptions = {
  onSuccess?: (data: AxiosResponse) => void;
  onError?: (error: Error) => void;
};

export function usePostTranslationFile({
  onSuccess,
  onError,
}: UsePostTranslationFileOptions = {}) {
  return useMutation({
    mutationFn: postTranslationFile,
    onSuccess,
    onError,
  });
}
