import * as React from 'react';

type TrashProps = {
  style?: React.CSSProperties;
};

export function Trash(props: TrashProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        stroke="#191F2E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M14 3H3l1 12h8l1-12H2m6 3v6m2.5-6l-.5 6M5.5 6l.5 6m0-9l.5-2h3l.5 2"
      />
    </svg>
  );
}
