import { css } from '@ghq-abi/design-system';

export const tabButtonStyles = css({
  fontWeight: 'bold',
  padding: '4px $sm',
  '&:hover, &:focus': {
    borderBottom: '2px solid $primaryDark',
    color: '$primaryDark',
    fontWeight: '$bold',
  },
  variants: {
    active: {
      false: {
        color: '$gray500',
        fontWeight: '$light',
      },
      true: {
        color: '$primaryDark',
        backgroundColor: '$blue50',
        background: 'none',
        borderBottom: '2px solid $primaryDark',

        '&:hover, &:focus': {
          borderBottom: '2px solid $primaryDark',
        },
      },
    },
  },
});
