/* eslint-disable no-underscore-dangle */
import { useLocale } from 'next-intl';
import { useQuery } from '@tanstack/react-query';

import { translationApi } from '~/shared/services/api';

export type GetWholeLanguageByKeyAPIResponse = {
  language: string;
  keys: string;
  _ts: number;
};

function getWholeLanguageByKey(key: string) {
  return translationApi.get<GetWholeLanguageByKeyAPIResponse>(
    `language/${key}`
  );
}

export function useGetWholeLanguageByKey(key: string) {
  const locale = useLocale();
  return useQuery({
    queryKey: ['useGetWholeLanguageByKey', key],
    queryFn: () => getWholeLanguageByKey(key),
    select: ({ data }) => ({
      ...data,
      timeStamp: data._ts
        ? new Intl.DateTimeFormat(locale, {
            timeZone: 'UTC',
          })
            .format(new Date(data._ts * 1000))
            .toString()
        : null,
    }),
  });
}
