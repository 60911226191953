export function isClient() {
  return typeof window !== 'undefined';
}

export function isServer() {
  return typeof window === 'undefined';
}

export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export function isTest() {
  return process.env.NODE_ENV === 'test';
}

export function getEnv() {
  if (process.env.NEXT_PUBLIC_ENV === 'staging') {
    return 'staging';
  }

  if (process.env.NEXT_PUBLIC_ENV === 'production') {
    return 'production';
  }

  if (process.env.NEXT_PUBLIC_ENV === 'dev') {
    return 'dev';
  }

  if (isDevelopment()) {
    return 'local';
  }

  return 'unknown';
}
