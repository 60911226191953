import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type ProfilePictureContextData = {
  isOpen: boolean;
  toggleOpen: () => void;
};

type ProfilePictureProviderProps = {
  children: React.ReactNode;
};

const ProfilePictureContext = createContext({} as ProfilePictureContextData);

export function ProfilePictureProvider({
  children,
}: ProfilePictureProviderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const providerValue = useMemo(
    () => ({
      isOpen,
      toggleOpen,
    }),
    [isOpen, toggleOpen]
  );

  return (
    <ProfilePictureContext.Provider value={providerValue}>
      {children}
    </ProfilePictureContext.Provider>
  );
}
export function useProfilePicture() {
  const context = useContext(ProfilePictureContext);
  if (!context) {
    throw new Error(
      'useProfilePicture must be used within a UseProfilePictureProvider'
    );
  }
  return context;
}
