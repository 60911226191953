import { type PagesPathsKeys, useFlags } from '~/app/contexts/FlagsContext';

type FlagsProps = {
  children: React.ReactNode;
  authorizedFlags: PagesPathsKeys | PagesPathsKeys[];
};

export function Flags({ children, authorizedFlags }: FlagsProps) {
  const flags = useFlags();

  if (typeof authorizedFlags === 'string' && !flags[authorizedFlags].active) {
    return null;
  }

  if (
    Array.isArray(authorizedFlags) &&
    !authorizedFlags.some(flag => flags[flag].active)
  ) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
