import { Box, styled } from '@ghq-abi/design-system';

export const DetailSpan = styled('span', {
  fontSize: '$xs',
  fontWeight: '$bold',
  color: '#2E2C34',
});

export const TextSpan = styled('span', {
  fontSize: '$xs',
  fontWeight: '$medium',
  color: '#8C93A3',
});

export const SquareIcon = styled('span', {
  display: 'inline-block',
  width: '$2',
  height: '$2',
  borderRadius: '$1',
  backgroundColor: '$gray600',
});

export const StatusBox = styled(Box, {
  p: '$sm',
  py: '$1-5',
  whiteSpace: 'nowrap',
  backgroundColor: '#F5F6F7',
  borderRadius: '$default',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$sm',

  variants: {
    transparent: {
      true: {
        backgroundColor: 'transparent',
      },
    },
    status: {
      notStarted: {
        [`& ${SquareIcon}`]: {
          backgroundColor: '#3F465A',
        },
      },
      inProgress: {
        [`& ${SquareIcon}`]: {
          backgroundColor: '#F2DC39',
        },
      },
      published: {
        [`& ${SquareIcon}`]: {
          backgroundColor: '#44AC21',
        },
      },
    },
    isButton: {
      true: {
        cursor: 'pointer',
        border: 0,
        transition: '$quickly-ease',

        '&:hover:not(:disabled)': {
          backgroundColor: '$gray300',
        },

        '&:active:not(:disabled)': {
          backgroundColor: '$gray410',
        },

        '&:disabled': {
          opacity: 0.6,
          cursor: 'not-allowed',
        },
      },
    },
    active: {
      true: {
        backgroundColor: '$gray410',
      },
    },
  },
});
