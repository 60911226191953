import * as React from 'react';

type ModulesProps = { size?: number; color?: string };

export function Modules({ size = 24, color = '#ffffff' }: ModulesProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      color={color}
    >
      <path
        d="M1 3.39935C1 4.72236 2.07635 5.7987 3.39936 5.7987C4.72237 5.7987 5.7987 4.72236 5.7987 3.39935C5.7987 2.07634 4.72237 1 3.39936 1C2.07635 1 1 2.07634 1 3.39935ZM2.12119 3.39188C2.12119 2.68926 2.69675 2.11372 3.39936 2.11372C4.10945 2.11372 4.67751 2.68926 4.67751 3.39188C4.67751 4.09449 4.10197 4.67004 3.39936 4.67004C2.69675 4.67004 2.12119 4.09449 2.12119 3.39188ZM9.79688 3.39935C9.79688 4.72236 10.8732 5.7987 12.1962 5.7987C13.5192 5.7987 14.5956 4.72236 14.5956 3.39935C14.5956 2.07634 13.5192 1 12.1962 1C10.8732 1 9.79688 2.07634 9.79688 3.39935ZM10.9181 3.39188C10.9181 2.68926 11.4936 2.11372 12.1962 2.11372C12.8988 2.11372 13.4744 2.68926 13.4744 3.39188C13.4744 4.09449 12.8988 4.67004 12.1962 4.67004C11.4936 4.67004 10.9181 4.09449 10.9181 3.39188ZM12.1962 15.0009C10.8732 15.0009 9.79688 13.9245 9.79688 12.6015C9.79688 11.2785 10.8732 10.2021 12.1962 10.2021C13.5192 10.2021 14.5956 11.2785 14.5956 12.6015C14.5956 13.9245 13.5192 15.0009 12.1962 15.0009ZM12.1962 11.3159C11.4936 11.3159 10.9181 11.8914 10.9181 12.594C10.9181 13.2966 11.4936 13.8722 12.1962 13.8722C12.8988 13.8722 13.4744 13.2966 13.4744 12.594C13.4744 11.8914 12.8988 11.3159 12.1962 11.3159ZM1 12.6015C1 13.9245 2.07635 15.0009 3.39936 15.0009C4.72237 15.0009 5.7987 13.9245 5.7987 12.6015C5.7987 11.2785 4.72237 10.2021 3.39936 10.2021C2.07635 10.2021 1 11.2785 1 12.6015ZM2.12119 12.594C2.12119 11.8914 2.69675 11.3159 3.39936 11.3159C4.10945 11.3159 4.67751 11.8914 4.67751 12.594C4.67751 13.2966 4.10197 13.8722 3.39936 13.8722C2.69675 13.8722 2.12119 13.2966 2.12119 12.594Z"
        fill="#191F2E"
      />
    </svg>
  );
}
