type UnlockEditProps = {
  size?: number;
  color?: string;
};

export function UnlockEdit({ size = 16, color = '#000' }: UnlockEditProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
    >
      <g clipPath="url(#clip0_7880_27570)">
        <path
          fill={color}
          d="M8 1.333c.727 0 1.411.195 2 .536a.666.666 0 11-.667 1.154 2.667 2.667 0 00-4 2.31l7.334.001A1.333 1.333 0 0114 6.667v6.667a1.333 1.333 0 01-1.333 1.333H3.333A1.333 1.333 0 012 13.334V6.667a1.333 1.333 0 011.333-1.334H4a4 4 0 014-4zm4.667 5.334H3.333v6.666h9.334V6.667zM8 8a1.333 1.333 0 01.756 2.431l-.09.057v.845a.667.667 0 01-1.328.078l-.005-.078v-.845A1.333 1.333 0 018 8zm5.279-4.653l.644.174a.667.667 0 01-.346 1.286l-.643-.172a.667.667 0 01.345-1.288zm-.857-1.954a.666.666 0 01.471.817l-.086.321a.666.666 0 11-1.288-.344l.087-.322a.666.666 0 01.816-.472z"
        />
      </g>
      <defs>
        <clipPath id="clip0_7880_27570">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
