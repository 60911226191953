type BottomThirdProps = {
  size?: number;
  color?: string;
};

export function BottomThird({ size = 16, color = '#000' }: BottomThirdProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
    >
      <path
        fill={color}
        d="M13 14.5a.5.5 0 01-.5.5h-9a.5.5 0 010-1h9a.5.5 0 01.5.5zM5 12V2.5a1 1 0 011-1h4a1 1 0 011 1V12a1 1 0 01-1 1H6a1 1 0 01-1-1zm1 0h4V2.5H6V12z"
      />
    </svg>
  );
}
