type XlsProps = {
  size?: number;
  color?: string;
};

export function Xls({ size = 20, color = '#000' }: XlsProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <path
        fill="currentColor"
        d="M17 18V16.836C17.591 16.555 18 15.952 18 15.254V10.75C18 10.052 17.591 9.45 17 9.168V7.828C16.9999 7.29761 16.7891 6.78899 16.414 6.414L10.585 0.586C10.5695 0.571627 10.5532 0.558266 10.536 0.546C10.5236 0.53645 10.5116 0.526443 10.5 0.516C10.4312 0.451071 10.358 0.39093 10.281 0.336C10.2554 0.319482 10.2287 0.304779 10.201 0.292L10.153 0.268L10.103 0.239C10.049 0.208 9.994 0.176 9.937 0.152C9.73786 0.0724632 9.52711 0.0258544 9.313 0.0139999C9.29322 0.0127123 9.27353 0.0103757 9.254 0.00699997C9.22686 0.00280811 9.19946 0.000469395 9.172 0H3C2.46957 0 1.96086 0.210714 1.58579 0.585786C1.21071 0.960859 1 1.46957 1 2V9.168C0.409 9.449 0 10.052 0 10.75V15.254C0 15.952 0.409 16.554 1 16.836V18C1 18.5304 1.21071 19.0391 1.58579 19.4142C1.96086 19.7893 2.46957 20 3 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18ZM15 18.5H3C2.86739 18.5 2.74021 18.4473 2.64645 18.3536C2.55268 18.2598 2.5 18.1326 2.5 18V17.004H15.5V18C15.5 18.1326 15.4473 18.2598 15.3536 18.3536C15.2598 18.4473 15.1326 18.5 15 18.5ZM15.5 8V9H2.5V2C2.5 1.86739 2.55268 1.74021 2.64645 1.64645C2.74021 1.55268 2.86739 1.5 3 1.5H9V6C9 6.53043 9.21071 7.03914 9.58579 7.41421C9.96086 7.78929 10.4696 8 11 8H15.5ZM14.378 6.5H11C10.8674 6.5 10.7402 6.44732 10.6464 6.35355C10.5527 6.25979 10.5 6.13261 10.5 6V2.621L14.378 6.5ZM1.75 10.5H16.25C16.3163 10.5 16.3799 10.5263 16.4268 10.5732C16.4737 10.6201 16.5 10.6837 16.5 10.75V15.254C16.5 15.3203 16.4737 15.3839 16.4268 15.4308C16.3799 15.4777 16.3163 15.504 16.25 15.504H1.75C1.6837 15.504 1.62011 15.4777 1.57322 15.4308C1.52634 15.3839 1.5 15.3203 1.5 15.254V10.75C1.5 10.6837 1.52634 10.6201 1.57322 10.5732C1.62011 10.5263 1.6837 10.5 1.75 10.5Z"
      />
      <path
        fill="currentColor"
        d="M4.13115 14.8178L4.13921 14.8271L4.14794 14.8358C4.23818 14.9252 4.35294 14.9766 4.48036 14.9766C4.64224 14.9766 4.77199 14.8926 4.8613 14.7775L4.86429 14.7737L5.55534 13.8243L6.25125 14.7634C6.3432 14.8942 6.4798 14.9766 6.6436 14.9766C6.77095 14.9766 6.89914 14.9293 6.99739 14.8178C7.09025 14.7159 7.13704 14.5856 7.13704 14.4468C7.13704 14.3366 7.10732 14.2183 7.0277 14.1192L6.17593 12.995L7.03637 11.8553L7.04095 11.8485C7.10229 11.758 7.13268 11.6528 7.13268 11.5438C7.13268 11.4025 7.08185 11.2719 6.98478 11.1733C6.89314 11.0771 6.77463 11.0234 6.6436 11.0234C6.50017 11.0234 6.35678 11.0847 6.26122 11.2268L5.57855 12.1647L4.8943 11.2316C4.84647 11.1644 4.78653 11.115 4.72229 11.0805C4.65265 11.0431 4.5767 11.0234 4.4978 11.0234C4.36669 11.0234 4.24368 11.0751 4.1481 11.1777C4.05059 11.2824 4.00872 11.4156 4.00872 11.5485C4.00872 11.6626 4.04001 11.7745 4.10741 11.871L4.11085 11.8759L4.96096 12.9998L4.1022 14.1333L4.09869 14.1384C4.03129 14.2348 4 14.3468 4 14.4609C4 14.5977 4.04786 14.7209 4.13115 14.8178Z"
      />
      <path
        fill="currentColor"
        d="M8.2212 14.7693L8.22672 14.7755L8.23255 14.7815C8.34259 14.8934 8.481 14.9532 8.6332 14.9532H10.1815C10.3108 14.9532 10.4323 14.9029 10.5273 14.803C10.6256 14.7029 10.6793 14.57 10.6793 14.4234C10.6793 14.2788 10.6271 14.1476 10.5313 14.0479C10.4385 13.9451 10.3162 13.889 10.1815 13.889H9.0525V11.5578C9.0525 11.4132 9.00026 11.282 8.90446 11.1823C8.81166 11.0795 8.6894 11.0234 8.55469 11.0234C8.41813 11.0234 8.29437 11.081 8.20111 11.1866C8.10811 11.2886 8.06125 11.419 8.06125 11.5578V14.3391C8.06125 14.5025 8.11695 14.6511 8.2212 14.7693Z"
      />
      <path
        fill="currentColor"
        d="M11.2207 14.5689C11.2741 14.6477 11.3452 14.7105 11.4318 14.7497C11.7932 14.9172 12.1791 15 12.5863 15C12.8397 15 13.0758 14.9552 13.2884 14.8569C13.498 14.7599 13.6744 14.6191 13.8036 14.4285L13.8045 14.4271L13.8054 14.4257C13.9348 14.2304 14 14.008 14 13.7679C14 13.5014 13.9354 13.2514 13.7753 13.0539C13.6449 12.8897 13.484 12.7671 13.2966 12.6889C13.139 12.622 12.9398 12.5588 12.7038 12.4978L12.7023 12.4974L12.7008 12.4971C12.5307 12.4552 12.4017 12.4178 12.3102 12.3854C12.2451 12.3589 12.1968 12.327 12.1605 12.2928C12.1559 12.2869 12.1508 12.2758 12.1508 12.2461C12.1508 12.1973 12.1613 12.1655 12.2213 12.1257C12.307 12.0709 12.4365 12.0314 12.6299 12.0314C12.8363 12.0314 13.0357 12.067 13.2293 12.1382C13.2703 12.1553 13.3321 12.1766 13.4019 12.1766C13.5359 12.1766 13.6632 12.1195 13.7552 12.0027C13.8295 11.9086 13.8735 11.7914 13.8735 11.6609C13.8735 11.5637 13.8519 11.4569 13.7893 11.3608C13.727 11.2653 13.6413 11.2069 13.5561 11.1738C13.2598 11.0562 12.9353 11 12.5863 11C12.3152 11 12.0649 11.0496 11.845 11.1614C11.6337 11.2672 11.4595 11.4196 11.3378 11.6237C11.2184 11.8208 11.1595 12.0421 11.1595 12.2789C11.1595 12.5431 11.2292 12.789 11.3954 12.9823C11.5305 13.1395 11.6926 13.2586 11.8784 13.3384L11.8808 13.3394L11.8832 13.3404C12.0426 13.4054 12.2444 13.4711 12.4851 13.5381C12.6592 13.5872 12.7854 13.6279 12.8686 13.6604L12.8723 13.6618L12.8761 13.6632C12.9394 13.6859 12.9815 13.7139 13.0101 13.7418L13.0125 13.7441C13.0117 13.8249 12.9899 13.8533 12.9563 13.877C12.9002 13.9166 12.7784 13.9592 12.5471 13.9592C12.2816 13.9592 12.0366 13.9039 11.8088 13.7949C11.7441 13.764 11.675 13.7485 11.605 13.7485C11.4714 13.7485 11.348 13.8047 11.2558 13.912L11.2551 13.9129C11.1705 14.012 11.129 14.1352 11.129 14.2642C11.129 14.3746 11.1617 14.4744 11.2138 14.5583L11.2172 14.5637L11.2207 14.5689Z"
      />
    </svg>
  );
}
