import * as React from 'react';
import { IconButtonV2, useResponsive } from '@ghq-abi/design-system';
import {
  FilterCircleFillIcon,
  FilterCircleIcon,
} from '@ghq-abi/design-system-icons';
import { useTranslate } from '@tolgee/react';

type FilterButtonProps = {
  isShowingFilters: boolean;
  disabled: boolean;
  size?: 'sm' | 'md' | 'lg';
  onClick: () => void;
};

export function FilterButton({
  isShowingFilters,
  disabled,
  size,
  onClick,
}: FilterButtonProps) {
  const { t } = useTranslate('web-app');

  const { isMobile } = useResponsive({ useOnlyAbiTokens: true });

  const sizeIcon = size || (isMobile ? 'sm' : 'md');

  return (
    <IconButtonV2
      variant="secondary"
      icon={isShowingFilters ? <FilterCircleFillIcon /> : <FilterCircleIcon />}
      size={sizeIcon}
      title={`${isShowingFilters ? t('common.hide') : t('common.show')} ${t(
        'common.filters'
      )}`}
      onClick={onClick}
      disabled={disabled}
    />
  );
}
