export function Eraser() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth="1.434"
        d="M14.927 8.057l-4.288-5.69-5.927 4.466 4.455 5.5 1.118-.778 4.642-3.498z"
      />
      <path
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth="1.434"
        d="M9.167 12.333l-1.28 1.025H4.366l-.867-1.15-2.144-2.845 3.478-2.621"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M4.402 13.357h10.453"
      />
    </svg>
  );
}
