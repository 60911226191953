import { CSS, styled, Text } from '@ghq-abi/design-system';
import { TurnoverRiskEnum } from 'abi-opr-common-types';

const RiskText = styled(Text, {
  fontWeight: '$bold',
  fontSize: '$sm',
  variants: {
    risk: {
      High: {
        color: '$blue350',
      },
      Medium: {
        color: '$blue350',
      },
      Low: {
        color: '$blue350',
      },
    },
  },
});

export function TurnoverRisk({
  risk,
  css,
}: {
  risk?: `${TurnoverRiskEnum}`;
  css?: CSS;
}) {
  return (
    <RiskText css={css} risk={risk}>
      {risk?.toUpperCase()}
    </RiskText>
  );
}
