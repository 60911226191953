import * as React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { CSS, Flex, Select } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { nextTickScheduler } from '~/shared/utils/nextTickScheduler';

type YearSelectProps = {
  id?: string;
  value?: string;
  disabled?: boolean;
  values?: string[] | null;
  placeholder?: string;
  css?: CSS;
  selectTriggerCss?: CSS;
  showNone?: boolean;
  onChange?: (value?: string) => void;
};

function generateYearsFromCurrentTo2023(): string[] {
  const currentYear = new Date().getFullYear();
  const years = [];

  // eslint-disable-next-line no-plusplus
  for (let year = currentYear; year >= 2023; year--) {
    years.push(year.toString());
  }

  return years;
}

export function YearSelect({
  id,
  value,
  disabled,
  values,
  placeholder,
  css,
  selectTriggerCss,
  showNone = true,
  onChange,
}: YearSelectProps) {
  const { t } = useTranslate('web-app');

  const [key, setKey] = React.useState(0);

  const selectTriggerRef = React.useRef<HTMLButtonElement>(null);

  const years = values || generateYearsFromCurrentTo2023();

  function handleValueChange(newValue: string) {
    onChange?.(newValue === 'none' ? undefined : newValue);

    if (newValue === 'none') {
      setKey(state => state + 1);
      nextTickScheduler(() => {
        selectTriggerRef.current?.focus();
      });
    }
  }

  return (
    <Flex css={{ position: 'relative', flex: 1, ...css }}>
      <Select
        key={key}
        value={value}
        onValueChange={handleValueChange}
        disabled={disabled}
      >
        <Select.Trigger
          ref={selectTriggerRef}
          id={id}
          css={{
            minWidth: 150,
            flex: 1,
            ...selectTriggerCss,
          }}
        >
          <Select.Value
            placeholder={placeholder ?? t('filters.zone.placeholder')}
          />
          <Select.Icon>
            <FaChevronDown />
          </Select.Icon>
        </Select.Trigger>
        <Select.Content>
          <Select.ScrollUpButton>
            <FaChevronUp />
          </Select.ScrollUpButton>
          <Select.Viewport>
            {showNone && (
              <Select.Item value="none">
                <Select.ItemText>{t('common.none')}</Select.ItemText>
              </Select.Item>
            )}
            {years?.map(year => (
              <Select.Item key={year} value={year}>
                <Select.ItemText>{year}</Select.ItemText>
              </Select.Item>
            ))}
          </Select.Viewport>
          <Select.ScrollDownButton>
            <FaChevronDown />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select>
    </Flex>
  );
}
