import { Skeleton, SkeletonProps } from '@ghq-abi/design-system';

type SkeletonsProp = {
  quantity: number;
} & SkeletonProps;

export function Skeletons({ quantity = 1, ...rest }: SkeletonsProp) {
  return (
    <>
      {Array.from({ length: quantity }, (_, i) => i).map(i => (
        <Skeleton
          key={i}
          variant="rectangle"
          css={{ width: '$full', minHeight: 50, m: 0 }}
          {...rest}
        />
      ))}
    </>
  );
}
