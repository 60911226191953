type CommentErrorProps = {
  size?: number;
  color?: string;
};

export function CommentError({
  size = 28,
  color = 'currentColor',
}: CommentErrorProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill={color}
        d="M27.792 7.8a7.2 7.2 0 11-14.4 0 7.2 7.2 0 0114.4 0zm-7.2-4a.8.8 0 00-.8.8v3.2a.8.8 0 001.6 0V4.6a.8.8 0 00-.8-.8zm0 8.2a1 1 0 100-2 1 1 0 000 2zm4 3.64v3.001c0 1.365-1.127 2.506-2.56 2.506H14.16L7.32 26.2h-.01v-5.053h-2.56c-1.433 0-2.56-1.141-2.56-2.506V7.905c0-1.365 1.127-2.505 2.56-2.505h7.372a8.66 8.66 0 01.629-1.6h-8C2.454 3.8.592 5.638.592 7.905v10.736c0 2.269 1.862 4.106 4.16 4.106h.96v3.473c0 .341.112.672.32.946.528.7 1.531.842 2.238.32l6.418-4.74h7.344c2.297 0 4.16-1.838 4.16-4.105v-4.053a8.81 8.81 0 01-1.6 1.052z"
      />
    </svg>
  );
}
