/* eslint-disable no-bitwise */
// https://www.sobyte.net/post/2022-02/js-crypto-randomuuid/

export const randomUUID = () => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
  if (typeof crypto === 'object' && typeof crypto.randomUUID === 'function') {
    return crypto.randomUUID();
  }

  // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
  if (
    typeof crypto === 'object' &&
    typeof crypto.getRandomValues === 'function' &&
    typeof Uint8Array === 'function'
  ) {
    const callback = (c: string) => {
      const num = Number(c);
      return (
        num ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))
      ).toString(16);
    };
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, callback);
  }

  // Without crypto
  let timestamp = new Date().getTime();
  let now =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0;

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let random = Math.random() * 16;
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0;
      timestamp = Math.floor(timestamp / 16);
    } else {
      random = (now + random) % 16 | 0;
      now = Math.floor(now / 16);
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
  });
};
