type DownloadProps = {
  size?: number;
  color?: string;
};

export function Download({ size = 20, color = '#325A6D' }: DownloadProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <path
        fill="currentColor"
        d="M10.46 13.755a.627.627 0 01-.922 0l-3.333-3.646a.625.625 0 01.923-.843l2.247 2.458V2.5a.625.625 0 011.25 0v9.223l2.248-2.458a.625.625 0 01.922.844l-3.334 3.646z"
      />
      <path
        fill="currentColor"
        d="M3.125 12.5a.625.625 0 10-1.25 0v.046c0 1.139 0 2.058.097 2.78.1.75.317 1.382.818 1.884.502.502 1.133.717 1.883.818.723.097 1.642.097 2.781.097h5.092c1.139 0 2.058 0 2.78-.097.75-.101 1.382-.316 1.884-.818s.717-1.133.818-1.883c.097-.723.097-1.642.097-2.781V12.5a.624.624 0 10-1.25 0c0 1.196-.002 2.03-.086 2.66-.082.612-.233.935-.463 1.166-.231.23-.554.38-1.167.463-.629.084-1.463.086-2.659.086h-5c-1.196 0-2.03-.002-2.66-.086-.612-.082-.935-.233-1.166-.463-.23-.231-.38-.554-.463-1.167-.084-.629-.086-1.463-.086-2.659z"
      />
    </svg>
  );
}
