import { useInfiniteQuery } from '@tanstack/react-query';
import type { AxiosRequestConfig } from 'axios';

import { oprApi } from '~/shared/services/api';
import { clearParams } from '~/shared/utils/clearParams';

import { Employee } from '../types';

export type GetEmployeesAPIResponse = {
  employee: Employee[];
  count: number;
  countEligible: number;
  countNotEligible: number;
};

export type GetEmployeesFilterParams = {
  employee_band?: string;
  employee_band_group?: string;
  employeeNameOrId?: string;
  function?: string;
  managerNameOrId?: string;
  zone?: string;
  viewContext?: string;
  workCountry?: string;
  hireDate?: string;
  eligibilityView?: 'all' | 'eligible' | 'ineligible';
  'to-export'?: boolean;
};

export type GetEmployeesParams = GetEmployeesFilterParams & {
  offset?: number;
  limit?: number;
  orderField?: string;
  orderValue?: 'asc' | 'desc';
};

const EMPLOYEES_START_PAGE = 20;

async function searchEmployees(
  { offset, limit, ...params }: GetEmployeesParams,
  config: AxiosRequestConfig = {}
) {
  const clearedParams = clearParams(params);

  if (
    clearedParams.eligibilityView &&
    clearedParams.eligibilityView !== 'all'
  ) {
    clearedParams.eligible = String(
      clearedParams.eligibilityView === 'eligible'
    );
  }

  delete clearedParams.eligibilityView;

  const response = await oprApi.get<GetEmployeesAPIResponse>('employees', {
    params: {
      ...clearedParams,
      limit,
      offset,
    },
    ...config,
  });

  return response.data;
}

export function useInfinityGetEmployees(params: GetEmployeesFilterParams) {
  return useInfiniteQuery(
    ['useInfinityGetEmployees', params],
    async ({ pageParam = 0 }) => {
      const response = await searchEmployees({
        ...params,
        limit: EMPLOYEES_START_PAGE,
        offset: pageParam,
      });

      return {
        ...response,
        pageParam,
      };
    },
    {
      getNextPageParam: lastPage => {
        const nextOffset = lastPage.pageParam + EMPLOYEES_START_PAGE;
        const hasNextPage = nextOffset < lastPage.count;
        return hasNextPage ? nextOffset : undefined;
      },
    }
  );
}
