import { useQuery } from '@tanstack/react-query';

import { oprApi } from '~/shared/services/api';

type GetWorkCountriesAPIResponse = Array<string>;

function getWorkCountries() {
  return oprApi.get<GetWorkCountriesAPIResponse>('employees/work-country');
}

export function useGetWorkCountries() {
  return useQuery({
    queryKey: ['useGetWorkCountries'],
    queryFn: getWorkCountries,
    select: ({ data }) =>
      data.sort((a, b) => a.localeCompare(b)).filter(Boolean),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
