import * as React from 'react';

type PastProps = {
  size?: number;
  color?: string;
};

export function UserArrowUp({ size = 18, color = '#325a6d' }: PastProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      color={color}
    >
      <path
        d="M14.0625 18C16.2371 18 18 16.2371 18 14.0625C18 11.8879 16.2371 10.125 14.0625 10.125C11.8879 10.125 10.125 11.8879 10.125 14.0625C10.125 16.2371 11.8879 18 14.0625 18ZM14.4602 11.4148L16.1477 13.1023C16.3674 13.3219 16.3674 13.6781 16.1477 13.8977C15.9281 14.1174 15.5719 14.1174 15.3523 13.8977L14.625 13.1705V16.3125C14.625 16.6232 14.3732 16.875 14.0625 16.875C13.7518 16.875 13.5 16.6232 13.5 16.3125V13.1705L12.7727 13.8977C12.5531 14.1174 12.1969 14.1174 11.9773 13.8977C11.7576 13.6781 11.7576 13.3219 11.9773 13.1023L13.6648 11.4148C13.7702 11.3093 13.9133 11.25 14.0625 11.25C14.2117 11.25 14.3548 11.3093 14.4602 11.4148Z"
        fill="currentColor"
      />
      <path
        d="M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625ZM9 7.875C10.2426 7.875 11.25 6.86764 11.25 5.625C11.25 4.38236 10.2426 3.375 9 3.375C7.75736 3.375 6.75 4.38236 6.75 5.625C6.75 6.86764 7.75736 7.875 9 7.875Z"
        fill="currentColor"
      />
      <path
        d="M9.28807 15.75C9.16103 15.3906 9.07343 15.0126 9.03047 14.6211H3.375C3.3766 14.3434 3.54797 13.5118 4.31111 12.7486C5.0449 12.0148 6.42474 11.25 8.99999 11.25C9.29305 11.25 9.57063 11.2599 9.83354 11.2785C10.0868 10.8945 10.391 10.5471 10.7365 10.2457C10.2117 10.1679 9.63448 10.125 9 10.125C3.375 10.125 2.25 13.5 2.25 14.625C2.25 15.75 3.375 15.75 3.375 15.75H9.28807Z"
        fill="currentColor"
      />
    </svg>
  );
}
