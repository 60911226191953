import Script from 'next/script';
import type { Session } from 'next-auth';
import { useLocale } from 'next-intl';

import packageJson from '../../../package.json';
import { getEnv } from '../utils/environments';

type DelightedScriptProps = {
  session?: Session;
};

export function DelightedScript({ session }: DelightedScriptProps) {
  const locale = useLocale();
  let delightedLocale = locale.split('-')[0];

  switch (locale) {
    case 'zh-CN':
    case 'zh-HK':
    case 'zh-TW':
    case 'pt-BR':
      delightedLocale = locale;
      break;
    default:
      break;
  }

  if (!session) {
    return null;
  }

  return (
    <Script id="delighted-script" strategy="lazyOnload">
      {/* https://app.delighted.com/docs/api/web */}
      {`!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"Nuxv3xgn7fewTs0U","delightedNps2");`}
      {`
        delightedNps2.survey({
          email: '${session.user.email}',
          name: '${session.user.name}',
          createdAt: '${new Date().toISOString()}',
          properties: {
            environment: '${getEnv()}',
            globalId: ${session.user.gid},
            locale: '${delightedLocale}',
            version: '${packageJson.version}',
          }
        });
      `}
    </Script>
  );
}
