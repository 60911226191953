import Link from 'next/link';
import { Flex, Text } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { ErrorText } from '../ErrorText';

type EmployeeWithNoDataProps = {
  employeeId: string;
  dataType: string;
};

export function EmployeeWithNoData({
  employeeId,
  dataType,
}: EmployeeWithNoDataProps) {
  const { t } = useTranslate('web-app');

  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      css={{
        width: '$full',
        height: '$full',
      }}
    >
      <ErrorText>
        <Text>
          {t(`${dataType}.other.employee_has_no_${dataType}`, {
            employeeId,
          })}
        </Text>
        <Link href="/home">{t('error_pages.404.go_back_to_home_page')}</Link>
      </ErrorText>
    </Flex>
  );
}
