import { globalCss } from '@ghq-abi/design-system';

export const globalStyles = globalCss({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  '@font-face': [
    {
      fontFamily: 'OpenSans',
      src: `url('/fonts/OpenSans-Light.woff2') format('woff2'),
      url('/fonts/OpenSans-Light.woff') format('woff')`,
      fontWeight: 300,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'OpenSans',
      src: `url('/fonts/OpenSans-Regular.woff2') format('woff2'),
      url('/fonts/OpenSans-Regular.woff') format('woff')`,
      fontWeight: 400,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
    // TODO: UX - Adding 600 option just to avoid confusion between UX Design System and codebase
    // Remove the 500 option after replace all fontWeights 500 with 600.
    {
      fontFamily: 'OpenSans',
      src: `url('/fonts/OpenSans-SemiBold.woff2') format('woff2'),
      url('/fonts/OpenSans-SemiBold.woff') format('woff')`,
      fontWeight: 500,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'OpenSans',
      src: `url('/fonts/OpenSans-SemiBold.woff2') format('woff2'),
      url('/fonts/OpenSans-SemiBold.woff') format('woff')`,
      fontWeight: 600,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'OpenSans',
      src: `url('/fonts/OpenSans-Bold.woff2') format('woff2'),
      url('/fonts/OpenSans-Bold.woff') format('woff')`,
      fontWeight: 700,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
    // TODO: UX - Adding 800 option just to avoid confusion between UX Design System and codebase
    // Remove the 900 option after replace all fontWeights 900 with 800.
    {
      fontFamily: 'OpenSans',
      src: `url('/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
      url('/fonts/OpenSans-ExtraBold.woff') format('woff')`,
      fontWeight: 800,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'OpenSans',
      src: `url('/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
      url('/fonts/OpenSans-ExtraBold.woff') format('woff')`,
      fontWeight: 900,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
  ],

  '*, *::before, *::after': {
    margin: 0,
    padding: 0,
    // TODO: UX Remove after DS udpate
    fontFamily: 'OpenSans, sans-serif !important',
    'box-sizing': 'border-box',

    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },

  'html, body, #__next': {
    minHeight: '100vh',
  },

  body: {
    backgroundColor: '$gray50',

    '::-webkit-scrollbar': {
      width: '6px !important',
      height: '6px !important',
    },
    '::-webkit-scrollbar-track': {
      borderRadius: '4px !important',
      background: '#f1f1f1 !important',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#7c7c7c !important',
      borderRadius: '20px !important',
      border: '3px solid #7c7c7c !important',
    },
    scrollbarColor: 'unset !important',
  },

  'body, input, button, textarea, select': {
    // TODO: UX Replace with var after DS udpate
    // fontFamily: '$openSans',
    fontFamily: 'OpenSans, sans-serif !important',
  },

  a: {
    textDecoration: 'none',
  },
});
