import { useEffect, useState } from 'react';

import { useAbility } from '~/app/contexts/AbilityContext';

import configs from './configs';

export const useStellarWidget = () => {
  const abilities = useAbility();

  const {
    stellarWidgetConnectorId,
    stellarWidgetEnabled,
    stellarWidgetTemplateId,
    stellarWidgetUrl,
    stellarWidgetOptions,
  } = configs;

  const [showStellarWidget, setShowStellarWidget] = useState<boolean>();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV === 'staging') {
      return setShowStellarWidget(
        stellarWidgetEnabled &&
          !stellarWidgetUrl.includes('STELLAR_WIDGET_URL') &&
          !stellarWidgetConnectorId.includes('STELLAR_WIDGET_CONNECTOR_ID') &&
          !stellarWidgetTemplateId.includes('STELLAR_WIDGET_TEMPLATE_ID') &&
          abilities.can('view', 'Stellar')
      );
    }
    return setShowStellarWidget(false);
  }, [
    stellarWidgetEnabled,
    stellarWidgetUrl,
    stellarWidgetConnectorId,
    stellarWidgetTemplateId,
    abilities,
  ]);

  useEffect(() => {
    if (showStellarWidget) {
      const script = document.createElement('script');
      script.setAttribute('src', configs.stellarWidgetUrl);
      script.setAttribute(
        'data-connector-id',
        configs.stellarWidgetConnectorId
      );
      script.setAttribute('data-template-id', configs.stellarWidgetTemplateId);
      script.setAttribute('async', '');

      if (stellarWidgetOptions) {
        script.setAttribute('options', stellarWidgetOptions);
      }

      const root = document.getElementsByTagName('body')[0] as HTMLElement;
      root.appendChild(script);
    }
  }, [showStellarWidget, stellarWidgetOptions]);
};
