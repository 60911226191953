import * as React from 'react';
import { Flex, IconButton, TextField, theme } from '@ghq-abi/design-system';
import { SearchIcon, XNewLgIcon } from '@ghq-abi/design-system-icons';
import { useTranslate } from '@tolgee/react';

type ClearableTextFieldProps = React.ComponentProps<typeof TextField> & {
  resetCss?: boolean;
  resetContainerCss?: boolean;
  onClear?: () => void;
};

export function ClearableTextField({
  value,
  css,
  containerCss,
  resetCss,
  resetContainerCss,
  onClear,
  ...props
}: ClearableTextFieldProps) {
  const { t } = useTranslate('web-app');

  return (
    <TextField
      value={value}
      rightIcon={
        <Flex
          css={{
            position: 'relative',
            width: theme.sizes[6].value,
            height: '$10',
          }}
          align="center"
        >
          <SearchIcon
            style={{
              position: 'absolute',
              width: theme.sizes[4].value,
              height: theme.sizes[4].value,
              transform: value ? 'translateX(10px)' : 'translateX(0)',
              opacity: value ? '0' : '1',
              transition: theme.transitions['slowly-ease'].value,
            }}
          />
          <IconButton
            onClick={onClear}
            disabled={props.disabled || !value}
            title={t('common.clear_field')}
            css={{
              border: 'none',
              transform: value ? 'translateX(0)' : 'translateX(10px)',
              opacity: value ? '1 !important' : '0 !important',
              pointerEvents: value ? 'all' : 'none',
              transition: theme.transitions['slowly-ease'].value,
            }}
            icon={
              <XNewLgIcon
                style={{
                  position: 'absolute',
                  width: theme.sizes[6].value,
                  height: theme.sizes[6].value,
                }}
              />
            }
          />
        </Flex>
      }
      css={
        resetCss
          ? css
          : {
              minWidth: 100,
              '@media (min-width: 700px)': { minWidth: 245 },
              ...css,
            }
      }
      containerCss={
        resetContainerCss
          ? containerCss
          : {
              flex: 1,
              height: '$10',
              '@xl': { maxWidth: '$xs', height: '$10' },
              ...containerCss,
            }
      }
      {...props}
    />
  );
}
