import React from 'react';
import { useDrop } from 'react-dnd';
import { Box } from '@ghq-abi/design-system';

import { DroppableProps } from './types';

export function Droppable<T>({ onDrop, children, item }: DroppableProps<T>) {
  const [, dropRef] = useDrop({
    accept: [item.type, 'content'],
    drop: (target: T) => {
      onDrop(target, true);
    },
  });

  return <Box ref={dropRef}>{children}</Box>;
}
