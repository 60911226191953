import { useMutation } from '@tanstack/react-query';
import {
  UpdateEmployeePreferredLanguageDto,
  UpdateEmployeePreferredLanguageModelView,
} from 'abi-opr-common-types';
import type { AxiosResponse } from 'axios';

import { oprApi } from '~/shared/services/api';

function updatePreferredLanguage(payload: UpdateEmployeePreferredLanguageDto) {
  return oprApi.patch<UpdateEmployeePreferredLanguageModelView>(
    'employees/preferred-language',
    payload
  );
}

type UseUpdatePreferredLanguageOptions = {
  onSuccess?: (
    data: AxiosResponse<UpdateEmployeePreferredLanguageModelView>
  ) => void;
  onError?: (error: Error) => void;
};

export function useUpdatePreferredLanguage({
  onSuccess,
  onError,
}: UseUpdatePreferredLanguageOptions = {}) {
  return useMutation({
    mutationFn: updatePreferredLanguage,
    onSuccess,
    onError,
  });
}
