import { IoWarningOutline } from 'react-icons/io5';
import {
  Box,
  CSS,
  Flex,
  IconButton,
  Text,
  Tooltip,
} from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { Avatar } from '../../../app/components/Avatar';

export type EmployeeCardProps = {
  id: string;
  globalId: string;
  name: string;
  title?: string;
  position?: string;
  zone?: string;
  eligible?: boolean;
  bg?: 'gray' | 'yellow' | 'green' | 'orange' | CSS['backgroundColor'];
  icon?: React.ReactNode;
  active?: boolean;
  warningText?: string;
  warningIcon?: React.ReactNode;
  onClick?: () => void;
};

function getColorByName(bg: EmployeeCardProps['bg']) {
  switch (bg) {
    case 'green':
      return '#EEFFF4';
    case 'yellow':
      return '#FFFCE8';
    case 'orange':
      return '#FFF1E7';
    case 'gray':
    default:
      return '#EEEFF2';
  }
}

export function EmployeeCard({
  id,
  globalId,
  name,
  bg,
  title,
  position,
  eligible,
  zone,
  active,
  icon,
  warningText,
  warningIcon,
  onClick,
}: EmployeeCardProps) {
  const { t } = useTranslate('web-app');
  const hasIcon = !!icon;

  return (
    <Box
      as="button"
      onClick={onClick}
      title={title}
      css={{
        bg: getColorByName(bg),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '$default',
        gap: '$sm',
        p: '$1',
        minHeight: '$10',
        cursor: 'pointer',
        border: 0,
        textAlign: 'left',
        transition: '$quickly-ease',

        '&:hover': { filter: 'brightness(0.96)' },
        '&:active': { filter: 'brightness(0.92)' },
        ...(active ? { filter: 'brightness(0.94)' } : {}),
      }}
    >
      <Flex gap="md" align="center">
        <Avatar
          name={name}
          employeeId={id}
          globalId={globalId}
          // TODO: UX - Add new size on DS lib
          imageCss={{ width: '$6', height: '$6' }}
          css={{
            minWidth: '$6',
            minHeight: '$6',
            width: '$6',
            height: '$6',
          }}
        />
        <Text
          css={{
            fontSize: '$xs',
            fontWeight: '$medium',
            lineHeight: '$4',
            color: '#2E2C34',
          }}
        >
          {name}
          <Text
            as="span"
            css={{
              display: 'block',
              fontSize: '$xs',
              lineHeight: '$4',
              color: '#6F6F6F',
              fontWeight: '$normal',
            }}
          >
            {[position, zone].filter(Boolean).join(' - ')}
          </Text>
        </Text>
      </Flex>

      <Flex gap="sm" align="center">
        {!eligible && (
          <Text
            css={{
              fontSize: '$0-5',
              fontWeight: '$medium',
              color: '$gray575',
              textAlign: 'center',
              maxWidth: 'min-content',
            }}
          >
            {t('common.not_eligible')}
          </Text>
        )}

        {hasIcon && icon}

        {warningText && (
          <Tooltip side="bottom" align="start" content={warningText}>
            <IconButton
              css={{
                background: 'none !important',
                border: 'none !important',
                boxShadow: 'none !important',
                p: 0,
                mx: 0,
                cursor: 'help',
                ':hover:not(:disabled)': { background: 'none !important' },
                svg: { height: '17px', width: '17px', fill: '$warning' },
                fontSize: '$0-5',
                fontWeight: '$medium',
                textAlign: 'center',
                color: '$warning',
              }}
              icon={warningIcon || <IoWarningOutline color="currentColor" />}
            />
          </Tooltip>
        )}
      </Flex>
    </Box>
  );
}
