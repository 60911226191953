interface IStellarWidgetConfigs {
  stellarWidgetConnectorId: string;
  stellarWidgetEnabled: boolean;
  stellarWidgetTemplateId: string;
  stellarWidgetUrl: string;
  stellarWidgetOptions: string;
  photoApiUrl: string;
}

const stellarWidgetConfigs = {} as IStellarWidgetConfigs;

if (process.env.NODE_ENV === 'development') {
  stellarWidgetConfigs.stellarWidgetConnectorId =
    '71c8e101-5aaf-437d-8c5d-10998711b016';
  stellarWidgetConfigs.stellarWidgetEnabled = true;
  stellarWidgetConfigs.stellarWidgetTemplateId =
    'eef84932-d1d7-4dbb-9c43-35338cd882ca';
  stellarWidgetConfigs.stellarWidgetUrl =
    'https://stellarwidgetpeopleuat.z20.web.core.windows.net/init.js';
  stellarWidgetConfigs.stellarWidgetOptions = '{"draggableWidget":"true"}';
  stellarWidgetConfigs.photoApiUrl =
    'https://dev.opr.ab-inbev.com/api/people-platform/photo/thumb';
} else {
  stellarWidgetConfigs.stellarWidgetConnectorId = process.env
    .NEXT_PUBLIC_STELLAR_WIDGET_CONNECTOR_ID
    ? process.env.NEXT_PUBLIC_STELLAR_WIDGET_CONNECTOR_ID
    : 'NEXT_PUBLIC_STELLAR_WIDGET_CONNECTOR_ID';
  stellarWidgetConfigs.stellarWidgetEnabled =
    process.env.NEXT_PUBLIC_STELLAR_WIDGET_ENABLED === 'true';
  stellarWidgetConfigs.stellarWidgetTemplateId = process.env
    .NEXT_PUBLIC_STELLAR_WIDGET_TEMPLATE_ID
    ? process.env.NEXT_PUBLIC_STELLAR_WIDGET_TEMPLATE_ID
    : 'NEXT_PUBLIC_STELLAR_WIDGET_TEMPLATE_ID';
  stellarWidgetConfigs.stellarWidgetUrl = process.env
    .NEXT_PUBLIC_STELLAR_WIDGET_URL
    ? process.env.NEXT_PUBLIC_STELLAR_WIDGET_URL
    : 'NEXT_PUBLIC_STELLAR_WIDGET_URL';
  stellarWidgetConfigs.stellarWidgetOptions = process.env
    .NEXT_PUBLIC_STELLAR_WIDGET_OPTIONS
    ? JSON.stringify(process.env.NEXT_PUBLIC_STELLAR_WIDGET_OPTIONS)
    : 'NEXT_PUBLIC_STELLAR_WIDGET_OPTIONS';
  stellarWidgetConfigs.photoApiUrl = process.env.NEXT_PUBLIC_PHOTO_API_URL
    ? process.env.NEXT_PUBLIC_PHOTO_API_URL
    : 'NEXT_PUBLIC_PHOTO_API_URL';
}

export default stellarWidgetConfigs;
