import { Button, Flex, theme } from '@ghq-abi/design-system';

type AccessibleButtonProps = React.ComponentProps<typeof Flex> & {
  buttonProps: React.ComponentProps<typeof Button>;
};

export function AccessibleButton({
  buttonProps,
  css,
  children,
  ...props
}: AccessibleButtonProps) {
  return (
    <Flex
      css={{
        ml: 40,
        mt: 10,
        position: 'absolute',
        overflow: 'hidden',
        ...css,
      }}
      {...props}
    >
      <Button
        variant="secondary"
        css={{
          all: 'unset',
          p: '0 $sm',
          opacity: 0,
          '&:focus': {
            boxShadow: `0 0 0px 1px ${theme.colors.primary} inset`,
            opacity: 1,
          },
          ...(buttonProps.css ?? {}),
        }}
        {...buttonProps}
      >
        {children}
      </Button>
    </Flex>
  );
}
