import * as React from 'react';
import { signOut } from 'next-auth/react';
import { parseCookies } from 'nookies';

import { COOKIE_KEYS } from '../constants/cookies';
import { nextTickScheduler } from '../utils/nextTickScheduler';

export function useExpireSession() {
  React.useEffect(() => {
    async function checkNonce() {
      const cookies = parseCookies();

      const USER_NONCE = process.env.NEXT_PUBLIC_USER_NONCE;

      if (typeof USER_NONCE === 'string') {
        await fetch('/api/create-user-nonce');

        if (cookies[COOKIE_KEYS.USER_NONCE] !== USER_NONCE) {
          nextTickScheduler(() => {
            signOut();
          });
        }
      }
    }

    checkNonce();
  }, []);
}
