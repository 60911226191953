import { styled } from '@ghq-abi/design-system';

import { getOPRRateColor } from '~/shared/utils/getOPRRateColor';

export const StyledSpan = styled('span', {
  fontSize: '$md',
  fontWeight: '$bold',
  color: '#000000',

  variants: {
    vote: {
      E: { color: getOPRRateColor('Excelling') },
      U: { color: getOPRRateColor('Underperformer') },
      M: { color: getOPRRateColor('Movers') },
      N: { color: getOPRRateColor('Newcomer') },
      NR: {},
    },
    size: {
      xs: { fontSize: '$xs' },
      sm: { fontSize: '$sm' },
      md: { fontSize: '$md' },
      lg: { fontSize: '$lg' },
      xl: { fontSize: '$xl' },
      '2xl': { fontSize: '$2xl' },
    },
  },
});
