import { ModuleDropdownProps } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { useAbility } from '~/app/contexts/AbilityContext';
import { Icon } from '~/shared/components';

export function useModuleItems() {
  const { t } = useTranslate('web-app');
  const ability = useAbility();

  const items: ModuleDropdownProps['items'] = [];

  if (
    ability.can('view', 'Cheershub') &&
    process.env.NEXT_PUBLIC_CHEERSHUB_MODULES_DROPDOWN_URL
  ) {
    items.push({
      label: t('menu.cheershub'),
      subtitle: t('menu.cheershub'),
      icon: <Icon.Cheershub color="#171717" size={16} />,
      url: process.env.NEXT_PUBLIC_CHEERSHUB_MODULES_DROPDOWN_URL,
    });
  }
  if (
    ability.can('view', 'MyLCM') &&
    process.env.NEXT_PUBLIC_LCM_MODULES_DROPDOWN_URL
  ) {
    items.push({
      label: t('menu.my_lcm'),
      subtitle: t('menu.lcm'),
      icon: <Icon.Past color="#171717" size={16} />,
      url: process.env.NEXT_PUBLIC_LCM_MODULES_DROPDOWN_URL,
    });
  }
  if (
    ability.can('view', 'Compensation') &&
    process.env.NEXT_PUBLIC_REWARDS_MODULES_DROPDOWN_URL
  ) {
    items.push({
      label: t('menu.my_compensation'),
      subtitle: t('menu.compensations'),
      icon: <Icon.MoneyBag color="#171717" size={16} />,
      url: process.env.NEXT_PUBLIC_REWARDS_MODULES_DROPDOWN_URL,
    });
  }
  if (process.env.NEXT_PUBLIC_NORTHSTAR_MODULES_DROPDOWN_URL) {
    items.push({
      label: t('menu.my_targets'),
      subtitle: t('menu.northstar'),
      icon: <Icon.Target color="#171717" size={16} />,
      url: process.env.NEXT_PUBLIC_NORTHSTAR_MODULES_DROPDOWN_URL,
    });
  }

  return { items };
}
