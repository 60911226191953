export function Workday() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_4269_27015)">
        <path
          fill="#fff"
          d="M8 .64A7.365 7.365 0 00.64 8 7.365 7.365 0 008 15.36 7.365 7.365 0 0015.36 8 7.365 7.365 0 008 .64zm0 .64A6.715 6.715 0 0114.72 8 6.715 6.715 0 018 14.72 6.715 6.715 0 011.28 8 6.715 6.715 0 018 1.28zm-.192 1.445a3.892 3.892 0 00-3.5 2.657.53.53 0 101.007.335 2.83 2.83 0 015.37 0 .53.53 0 101.008-.335 3.892 3.892 0 00-3.885-2.657zm.609 4.636h-.03c-.258 0-.517-.003-.775.003a.273.273 0 00-.267.216l-.89 3.317c-.092.367-.163.74-.212 1.115-.042-.261-.051-.527-.096-.788-.043-.291-.125-.575-.191-.86-.227-.927-.45-1.855-.678-2.782a.24.24 0 00-.216-.215c-.232-.01-.464-.006-.696-.004a.233.233 0 00-.181.108c0 .068.005.136.016.203l1.264 4.615a.217.217 0 00.209.184c.3.012.6 0 .901.005.15.012.354-.024.387-.201l.883-3.283c.075-.335.131-.674.17-1.015.032.382.095.76.188 1.133.287 1.063.57 2.126.86 3.188a.234.234 0 00.259.177c.323-.002.647.006.97-.005a.27.27 0 00.257-.222c.417-1.545.835-3.089 1.254-4.633a.18.18 0 00-.012-.164.182.182 0 00-.139-.087c-.208-.01-.418-.005-.626-.003a.266.266 0 00-.284.194c-.149.656-.293 1.314-.44 1.97-.207.819-.366 1.65-.478 2.487-.03-.147-.051-.295-.072-.443-.1-.53-.228-1.056-.385-1.572-.22-.808-.44-1.615-.661-2.42a.307.307 0 00-.289-.218z"
        />
      </g>
      <defs>
        <clipPath id="clip0_4269_27015">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
