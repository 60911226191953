type ChatQuestionProps = {
  size?: number;
  color?: string;
};

export function ChatQuestion({
  size = 20,
  color = '#171717',
}: ChatQuestionProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M11.667 15.833c3.143 0 4.714 0 5.69-.976.977-.976.977-2.548.977-5.69 0-3.143 0-4.715-.977-5.69-.976-.977-2.547-.977-5.69-.977H8.334c-3.143 0-4.715 0-5.69.977-.977.975-.977 2.547-.977 5.69 0 3.142 0 4.714.977 5.69.544.545 1.273.786 2.356.891"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.667"
        d="M8.334 7.07c.417-.825.833-1.237 1.667-1.237 1.038 0 1.666.825 1.666 1.649 0 .824-.416.86-1.666 1.685V10m0 2.083v.417m1.666 3.333c-1.03 0-2.165.417-3.2.955-1.665.864-2.498 1.296-2.908 1.02-.41-.275-.332-1.129-.177-2.836l.035-.389"
      />
    </svg>
  );
}
