import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, Flex, styled } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';
import { title } from 'process';

import { Can } from '~/shared/components';

import { tabButtonStyles } from './styles';

export const TabLink = styled('a', tabButtonStyles);

export function ProfileTabSwitch() {
  const { route } = useRouter();
  const { t } = useTranslate('web-app');

  return (
    <Can I="view" a="PersonalDashboardV2">
      {() => (
        <Flex
          css={{
            justifyContent: 'space-between',
            width: '220px',
            marginBottom: '-16px',
          }}
        >
          <Link href="/talent-card/me" passHref legacyBehavior>
            <TabLink active={route === '/talent-card/me'} title={title}>
              <Box as="span" css={{ fontSize: '$sm', whiteSpace: 'nowrap' }}>
                {t('profile_tab_switch.your_card')}
              </Box>
            </TabLink>
          </Link>
          <Link href="/dashboard/v2" passHref legacyBehavior>
            <TabLink active={route === '/dashboard/v2'} title={title}>
              <Box css={{ fontSize: '$sm', whiteSpace: 'nowrap' }} as="span">
                {t('profile_tab_switch.dashboard')}
              </Box>
            </TabLink>
          </Link>
        </Flex>
      )}
    </Can>
  );
}
