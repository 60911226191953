import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { Session } from 'next-auth';

import { User } from '~/shared/auth/useUser';

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID ?? '';

type AnalyticEvents =
  | 'Dashboard_download'
  | 'Talent_card_download'
  | 'Manager_assessment_opr_rate'
  | 'Manager_assessment_publish'
  | 'Eligibility_change_no'
  | 'Eligibility_change_yes'
  | 'Manager_assessment_copy_2as'
  | 'Manager_assessment_change_copy_2as'
  | 'Meeting_start'
  | 'Meeting_add_participant'
  | 'Talent_card_2as_ambitions_edit'
  | 'Talent_card_2as_ambitions_publish'
  | 'Talent_card_2as_achievements_edit'
  | 'Talent_card_2as_achievements_publish'
  | 'Download_meeting_details_report'
  | 'Download_my_organization_report'
  | 'Download_talent_performance_report'
  | 'Download_talent_pool_report'
  | 'Download_opr_report'
  | 'Download_talent_search_report'
  | 'Meeting_vote_nomination'
  | 'Meeting_save_and_continue'
  | 'Meeting_overview'
  | 'Meeting_shapes';

type GTagEvent = {
  action: AnalyticEvents;
  category: string;
  label: string;
  value: number;
  user: User;
};

function fireEvent({ action, category, label, value, user }: GTagEvent) {
  if (!GA_TRACKING_ID) {
    return;
  }
  if (!window.gtag) return;
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
    user_properties: {
      globalId: user.globalId,
      band: user.band,
      zone: user.zone,
      isManager: user.roles?.isManager,
      isSuperAdmin: user.roles?.isSuperAdmin,
      isGlobalAdmin: user.roles?.isGlobalAdmin,
      isZoneAdmin: user.roles?.isZoneAdmin,
      isPBP: user.roles?.isPBP,
      isTalentManager: user.roles?.isTalentManager,
    },
  });
}

function usePageView(session?: Session | null) {
  const router = useRouter();

  useEffect(() => {
    function handleRouteChange(url: URL) {
      if (!GA_TRACKING_ID && !session?.user) return;
      if (!window.gtag) return;
      window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
        user_properties: {
          country: session?.user.ctry,
          employeeId: session?.user.uid,
          zone: session?.user.zone,
        },
      });
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [
    router.events,
    session?.user,
    session?.user.ctry,
    session?.user.uid,
    session?.user.zone,
  ]);
}

type GoogleAnalyticsScriptProps = {
  session?: Session | null;
};

function GoogleAnalyticsScript({ session }: GoogleAnalyticsScriptProps) {
  usePageView(session);

  if (!GA_TRACKING_ID) {
    return null;
  }

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script id="gtag-init" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_TRACKING_ID}', {
            page_path: window.location.pathname,
          });
        `}
      </Script>
    </>
  );
}

export const GA = {
  fireEvent,
  Script: GoogleAnalyticsScript,
};
