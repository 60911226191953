export function Copy() {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 13.6668C0.966667 13.6668 0.652667 13.5364 0.391333 13.2755C0.130444 13.0142 0 12.7002 0 12.3335V3.00016H1.33333V12.3335H8.66667V13.6668H1.33333ZM4 11.0002C3.63333 11.0002 3.31956 10.8697 3.05867 10.6088C2.79733 10.3475 2.66667 10.0335 2.66667 9.66683V1.66683C2.66667 1.30016 2.79733 0.986163 3.05867 0.724829C3.31956 0.46394 3.63333 0.333496 4 0.333496H10C10.3667 0.333496 10.6807 0.46394 10.942 0.724829C11.2029 0.986163 11.3333 1.30016 11.3333 1.66683V9.66683C11.3333 10.0335 11.2029 10.3475 10.942 10.6088C10.6807 10.8697 10.3667 11.0002 10 11.0002H4ZM4 9.66683H10V1.66683H4V9.66683ZM4 9.66683V1.66683V9.66683Z"
        fill="#171717"
      />
    </svg>
  );
}
