import { useQuery } from '@tanstack/react-query';

import { oprApi } from '~/shared/services/api';

type GetAbiEntityAPIResponse = Array<string>;

async function getAbiEntity() {
  const response = await oprApi.get<GetAbiEntityAPIResponse>(
    'employees/abi-entity'
  );
  return response.data;
}

export function useGetAbiEntity() {
  return useQuery({
    queryKey: ['useGetAbiEntity'],
    queryFn: getAbiEntity,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
