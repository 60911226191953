import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslate } from '@tolgee/react';
import { ActionEnum } from 'abi-opr-common-types';

import {
  CheersHubIcon,
  OtherAppsIcon,
  OurAppsIcon,
} from '~/shared/components/icons';

import { useMenuRoutes } from '../components/Nav/hooks/useMenuRoutes';
import { RouteObject } from '../types';

import { useAbility } from './AbilityContext';

type NavContextData = {
  isAppMenuOpen: boolean;
  subMenuOpen?: RouteObject | null;
  menuHeaderItem: RouteObject;
  menuItems: RouteObject[];
  footerItems: RouteObject[];
  toggleAppMenu: () => void;
  toggleSubMenu: (item: RouteObject | null) => void;
  checkIfIsActive: (menuRoute: string, subitems?: RouteObject[]) => boolean;
};

const NavContext = React.createContext({} as NavContextData);

type NavProviderProps = {
  children: React.ReactNode;
};

export function NavProvider({ children }: NavProviderProps) {
  const { t } = useTranslate('web-app');
  const ability = useAbility();

  const router = useRouter();

  const [isAppMenuOpen, setIsAppMenuOpen] = React.useState(false);
  const [subMenuOpen, setSubmenuOpen] = React.useState<RouteObject | null>();
  const { menuRoutes, footerRoutes } = useMenuRoutes();

  const toggleSubMenu = (item: RouteObject | null) => {
    setSubmenuOpen(item?.routeComponents?.length ? item : null);
  };

  const checkIfIsActive = React.useCallback(
    (menuRoute: string, subitems?: RouteObject[]) => {
      const hasSomeSubitemActive = subitems?.some(
        subitem => subitem.path === router.route
      );

      return (
        (!!menuRoute && router.route === menuRoute) || !!hasSomeSubitemActive
      );
    },
    [router.route]
  );

  const ourApps: RouteObject[] = React.useMemo(() => {
    const values: RouteObject[] = [];

    if (
      ability.can(ActionEnum.View, 'MyLCM') &&
      process.env.NEXT_PUBLIC_LCM_MODULES_DROPDOWN_URL
    ) {
      values.push({
        key: 'lcm',
        title: '180/360',
        path: process.env.NEXT_PUBLIC_LCM_MODULES_DROPDOWN_URL,
        icon: <OtherAppsIcon />,
      });
    }

    if (
      ability.can(ActionEnum.View, 'Cheershub') &&
      process.env.NEXT_PUBLIC_CHEERSHUB_MODULES_DROPDOWN_URL
    ) {
      values.push({
        key: 'cheersHub',
        title: 'CheersHub',
        path: process.env.NEXT_PUBLIC_CHEERSHUB_MODULES_DROPDOWN_URL,
        icon: <CheersHubIcon />,
      });
    }

    if (
      ability.can(ActionEnum.View, 'Compensation') &&
      process.env.NEXT_PUBLIC_REWARDS_MODULES_DROPDOWN_URL
    ) {
      values.push({
        key: 'compensation',
        title: 'Compensation',
        path: process.env.NEXT_PUBLIC_REWARDS_MODULES_DROPDOWN_URL,
        icon: <OtherAppsIcon />,
      });
    }

    if (process.env.NEXT_PUBLIC_NORTHSTAR_MODULES_DROPDOWN_URL) {
      values.push({
        key: 'northstar',
        title: 'NorthStar',
        path: process.env.NEXT_PUBLIC_NORTHSTAR_MODULES_DROPDOWN_URL,
        icon: <OtherAppsIcon />,
      });
    }

    if (process.env.NEXT_PUBLIC_WORKDAY_URL) {
      values.push({
        key: 'workday',
        title: 'Workday',
        path: process.env.NEXT_PUBLIC_WORKDAY_URL,
        icon: <OtherAppsIcon />,
      });
    }

    return values;
  }, [ability]);

  function toggleAppMenu() {
    setIsAppMenuOpen(state => !state);
  }

  const providerValue = React.useMemo(
    () => ({
      isAppMenuOpen,
      subMenuOpen,
      menuItems: menuRoutes,
      footerItems: footerRoutes,
      menuHeaderItem: {
        key: 'our_apps',
        title: t('common.menu_our_apps'),
        path: '/',
        icon: <OurAppsIcon />,
        routeComponents: ourApps,
      },
      toggleAppMenu,
      toggleSubMenu,
      checkIfIsActive,
    }),
    [
      isAppMenuOpen,
      subMenuOpen,
      menuRoutes,
      footerRoutes,
      t,
      ourApps,
      checkIfIsActive,
    ]
  );

  return (
    <NavContext.Provider value={providerValue}>{children}</NavContext.Provider>
  );
}

export function useNav() {
  const context = React.useContext(NavContext);

  if (!context) {
    throw new Error('useNav must be used within a NavProvider');
  }

  return context;
}
