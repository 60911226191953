import Script from 'next/script';

type NpsMetricScriptProps = {
  globalId?: string;
  preferredLanguage?: string;
};

function NpsMetricScript({
  globalId,
  preferredLanguage,
}: NpsMetricScriptProps) {
  if (!globalId) return null;
  return (
    <>
      <Script
        type="text/javascript"
        src={process.env.NEXT_PUBLIC_METRICS_URL}
        strategy="afterInteractive"
      />
      <Script id="plugin" strategy="afterInteractive">
        {`
            var metricsPlugin;
            setTimeout(() => {
                metricsPlugin.plugin("${globalId}","OPR","${preferredLanguage}")
            }, 2000)
        `}
      </Script>
    </>
  );
}

export const NPS = {
  Script: NpsMetricScript,
};
