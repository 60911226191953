type PendingProps = {
  size?: number;
  color?: string;
};

export function Pending({ size = 20, color = '#f7ce00' }: PendingProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <path
        fill="currentColor"
        d="M10.833 1.667v1.666a6.662 6.662 0 013.896 11.329 6.678 6.678 0 01-3.896 1.905v1.666c4.584-.5 7.875-4.616 7.375-9.191-.433-3.884-3.491-6.959-7.375-7.375zm-1.666 0A8.164 8.164 0 004.725 3.5l1.192 1.283a6.663 6.663 0 013.25-1.4V1.667zM3.55 4.725a8.175 8.175 0 00-1.842 4.442h1.667a6.68 6.68 0 011.367-3.25L3.55 4.725zm-1.833 6.108a8.367 8.367 0 001.841 4.442l1.184-1.192a6.668 6.668 0 01-1.359-3.25H1.717zm4.166 4.475L4.725 16.45a8.329 8.329 0 004.442 1.883v-1.666a6.668 6.668 0 01-3.25-1.359h-.034z"
      />
    </svg>
  );
}
