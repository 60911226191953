type NotEnoughInfoProps = {
  size?: number;
  color?: string;
};

export function NotEnoughInfo({
  size = 28,
  color = 'currentColor',
}: NotEnoughInfoProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 6.91625L6.41625 5.75L15.75 15.083L14.583 16.25L5.25 6.91625Z"
        fill={color}
      />
      <path
        d="M10.5002 0.500201C9.11893 0.491636 7.7497 0.75738 6.47191 1.28202C5.19413 1.80666 4.03321 2.57976 3.05649 3.55649C2.07976 4.53321 1.30666 5.69413 0.782021 6.97191C0.25738 8.2497 -0.00836352 9.61893 0.000200593 11.0002C-0.00836352 12.3815 0.25738 13.7507 0.782021 15.0285C1.30666 16.3063 2.07976 17.4672 3.05649 18.4439C4.03321 19.4206 5.19413 20.1937 6.47191 20.7184C7.7497 21.243 9.11893 21.5088 10.5002 21.5002C11.8815 21.5088 13.2507 21.243 14.5285 20.7184C15.8063 20.1937 16.9672 19.4206 17.9439 18.4439C18.9206 17.4672 19.6937 16.3063 20.2184 15.0285C20.743 13.7507 21.0088 12.3815 21.0002 11.0002C21.0088 9.61893 20.743 8.2497 20.2184 6.97191C19.6937 5.69413 18.9206 4.53321 17.9439 3.55649C16.9672 2.57976 15.8063 1.80666 14.5285 1.28202C13.2507 0.75738 11.8815 0.491636 10.5002 0.500201ZM10.5002 20.0002C8.72017 20.0002 6.98011 19.4724 5.50007 18.4834C4.02003 17.4945 2.86647 16.0889 2.18528 14.4444C1.5041 12.7998 1.32587 10.9902 1.67313 9.24439C2.0204 7.49856 2.87757 5.89491 4.13624 4.63624C5.39491 3.37757 6.99856 2.5204 8.74439 2.17313C10.4902 1.82587 12.2998 2.0041 13.9444 2.68528C15.5889 3.36647 16.9945 4.52003 17.9834 6.00007C18.9724 7.48011 19.5002 9.22017 19.5002 11.0002C19.4933 13.385 18.5428 15.6702 16.8565 17.3565C15.1702 19.0428 12.885 19.9933 10.5002 20.0002Z"
        fill={color}
      />
    </svg>
  );
}
