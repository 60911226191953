import { useQuery } from '@tanstack/react-query';

import { oprApi } from '~/shared/services/api';

type GetFunctionsAPIResponse = Array<string>;

async function getFunctions() {
  const response = await oprApi.get<GetFunctionsAPIResponse>(
    'employees/functions'
  );
  return response.data;
}

export function useGetFunctions() {
  return useQuery({
    queryKey: ['useGetFunctions'],
    queryFn: getFunctions,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
