import { AiOutlineFullscreenExit } from 'react-icons/ai';
import { IconButton } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { useFullscreen } from '~/app/contexts/FullscreenContext';

export function FullScreenDeactivate() {
  const { t } = useTranslate('web-app');

  const fullscreen = useFullscreen();

  return (
    <IconButton
      icon={<AiOutlineFullscreenExit size={24} />}
      css={{
        zIndex: '$sticky',
        position: 'fixed',
        top: '$lg',
        right: '$lg',
        svg: {
          color: '$gray750',
          width: 'unset',
          height: 'unset',
        },
      }}
      title={t('actions.deactivate', { t: t('common.fullscreen') })}
      onClick={fullscreen.deactivate}
    />
  );
}
