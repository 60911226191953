type SortBoolDescProps = {
  size?: number;
  color?: string;
};

export function SortBoolDesc({
  size = 17,
  color = '#6f6f6f',
}: SortBoolDescProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <path
        fill="currentColor"
        d="M12.784 5.167h2L12.118 2.5 9.45 5.167h2V14.5h1.333M3.451 9.167c-.74 0-1.333.593-1.333 1.333v2.667c0 .74.593 1.333 1.333 1.333h2.667c.74 0 1.333-.593 1.333-1.333V10.5c0-.74-.593-1.333-1.333-1.333m.133 1l.707.7-2.66 2.633-1.687-1.7.713-.7.98.993M3.451 2.5c-.74 0-1.333.593-1.333 1.333V6.5c0 .74.593 1.333 1.333 1.333h2.667c.74 0 1.333-.593 1.333-1.333V3.833c0-.74-.593-1.333-1.333-1.333M3.45 3.833h2.667V6.5H3.45V3.833z"
      />
    </svg>
  );
}
