import { useQuery } from '@tanstack/react-query';

import { oprApi } from '~/shared/services/api';

type GetEmployeeTypesAPIResponse = Array<string>;

async function getEmployeeTypes() {
  const response = await oprApi.get<GetEmployeeTypesAPIResponse>(
    'employees/types'
  );

  return response.data;
}

export function useGetEmployeeTypes() {
  return useQuery({
    queryKey: ['useGetEmployeeTypes'],
    queryFn: getEmployeeTypes,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
