import * as React from 'react';
import { RiQuestionLine } from 'react-icons/ri';
import { CSS, Flex, Tooltip } from '@ghq-abi/design-system';

type QuestionTooltipProps = Omit<
  React.ComponentProps<typeof Tooltip>,
  'children'
> & {
  iconSize?: number;
  buttonCss?: CSS;
};

export function QuestionTooltip({
  iconSize = 18,
  buttonCss,
  ...props
}: QuestionTooltipProps) {
  return (
    <Tooltip {...props}>
      <Flex
        as="button"
        type="button"
        justify="center"
        align="center"
        css={{
          appearance: 'none',
          border: 0,
          bg: 'transparent',
          cursor: 'help',
          color: '#325A6D',
          ...buttonCss,
        }}
      >
        <RiQuestionLine size={iconSize} />
      </Flex>
    </Tooltip>
  );
}
