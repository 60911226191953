export type OPRRates =
  | 'Underperformer'
  | 'Movers'
  | 'Excelling'
  | 'Newcomer'
  | 'Not Rated';

export type OPRRatesAmounts =
  | 'Underperformer_Amount'
  | 'Movers_Amount'
  | 'Excelling_Amount'
  | 'Newcomer_Amount'
  | 'Not Rated_Amount';

export function getOPRRateColor(rate: OPRRates | OPRRatesAmounts) {
  switch (rate) {
    case 'Underperformer':
    case 'Underperformer_Amount':
      return '#797979';
    case 'Movers':
    case 'Movers_Amount':
      return '#a67a2f';
    case 'Excelling':
    case 'Excelling_Amount':
      return '#1b3c50';
    case 'Newcomer':
    case 'Newcomer_Amount':
      return '#5c9ac3';
    case 'Not Rated':
    case 'Not Rated_Amount':
      return '#bcbcbc';
    default:
      return '';
  }
}
