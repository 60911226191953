export function rankBand(band: string) {
  const rankingTable = {
    I: 1,
    II: 2,
    III: 3,
    IV: 4,
    V: 5,
    VI: 6,
    VII: 7,
    VIII: 8,
    IX: 9,
    X: 10,
    XI: 11,
    XII: 12,
  };

  if (Object.keys(rankingTable).includes(band as keyof typeof rankingTable)) {
    const bandGroup = band as keyof typeof rankingTable;
    return rankingTable[bandGroup];
  }

  if (band.includes('USA - CRI')) {
    return rankingTable.III;
  }
  if (band.includes('USA - CRH') || band.includes('CAN-CR-H')) {
    return rankingTable.IV;
  }
  if (
    band.includes('USA - CRG') ||
    band.includes('CAN-CR-G') ||
    band.includes('CAN-CR-F')
  ) {
    return rankingTable.V;
  }
  if (
    band.includes('USA - CRD') ||
    band.includes('CAN-CR-E') ||
    band.includes('USA - CRE') ||
    band.includes('USA - CRF')
  ) {
    return rankingTable.VI;
  }
  if (
    band.includes('USA - CRC') ||
    band.includes('CAN-CR-D') ||
    band.includes('USA - CRB')
  ) {
    return rankingTable.VII;
  }
  if (band.includes('CAN-CR-C') || band.includes('USA - CRA')) {
    return rankingTable.VIII;
  }
  if (band.includes('CAN-CR-A')) {
    return rankingTable.X;
  }
  if (
    band.includes('USD2') ||
    band.includes('US-USD4') ||
    band.includes('US')
  ) {
    return rankingTable.XII;
  }

  return band === '0' ? 0 : 13;
}
