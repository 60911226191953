import { useSession } from 'next-auth/react';
import { LanguagesEnum } from 'abi-opr-common-types';

export function useUser() {
  const { data: session } = useSession();

  return {
    name: session?.user.px?.name ?? session?.user.name,
    zone: session?.user.px?.zone ?? session?.user.zone,
    globalId: session?.user.px?.gid ?? session?.user.gid,
    employeeId: session?.user.px?.uid ?? session?.user.uid,
    roles: session?.user.px?.rols ?? session?.user.rols,
    managedZones: session?.user.px?.zones ?? session?.user.zones,
    language: session?.user.lcl as keyof typeof LanguagesEnum,
    isProxied: !!session?.user.px,
    band: session?.user.px?.band ?? session?.user.band,
  };
}

export type User = ReturnType<typeof useUser>;
