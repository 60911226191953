import * as React from 'react';
import { useLocale } from 'next-intl';
import { CalendarField } from '@ghq-abi/design-system';

import type { Language } from '~/shared/constants/i18n';

type CalendarProps = React.ComponentProps<typeof CalendarField>;

type DateFnsLocale = {
  code: string;
};

const i18nLocaleToDateFnsLocale: Partial<Record<Language, string>> = {
  'de-DE': 'de',
  'it-IT': 'it',
  'ko-KR': 'ko',
  'ru-RU': 'ru',
  'uk-UA': 'uk',
  'fr-FR': 'fr',
  'ja-JP': 'ja',
  'vi-VN': 'vi',
};

export function Calendar(props: CalendarProps) {
  const [dateFnsLocale, setDateFnsLocale] = React.useState<DateFnsLocale>();
  const locale = useLocale();

  React.useEffect(() => {
    async function loadLocale() {
      const localeToSet = await import(
        `date-fns/locale/${
          i18nLocaleToDateFnsLocale[locale as Language] ?? locale
        }/index.js`
      );
      setDateFnsLocale(localeToSet.default);
    }

    if (locale !== dateFnsLocale?.code) {
      loadLocale();
    }
  }, [dateFnsLocale, locale]);

  return <CalendarField {...props} locale={dateFnsLocale} />;
}
