type RefreshProps = {
  size?: number;
  color?: string;
};

export function Refresh({ size = 20, color = '#171717' }: RefreshProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={color}
        d="M10 16.667c-1.861 0-3.437-.646-4.73-1.938-1.29-1.292-1.937-2.868-1.937-4.73 0-1.86.646-3.437 1.938-4.728C6.563 3.979 8.139 3.333 10 3.333c.958 0 1.875.198 2.75.594A6.348 6.348 0 0115 5.625V3.333h1.667v5.834h-5.834V7.5h3.5a4.865 4.865 0 00-1.822-1.833A4.942 4.942 0 0010 5c-1.389 0-2.57.486-3.542 1.458S5 8.611 5 10c0 1.389.486 2.57 1.458 3.542C7.431 14.514 8.611 15 10 15c1.07 0 2.035-.306 2.896-.917a4.841 4.841 0 001.812-2.416h1.75a6.58 6.58 0 01-2.375 3.604c-1.194.93-2.555 1.396-4.083 1.396z"
      />
    </svg>
  );
}
