export function Profile() {
  return (
    <svg
      width="16"
      height="23"
      viewBox="0 0 16 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99993 14.2377C8.55767 14.2377 9.10289 14.0723 9.56664 13.7624C10.0304 13.4525 10.3918 13.0121 10.6053 12.4968C10.8187 11.9815 10.8746 11.4145 10.7657 10.8675C10.6569 10.3205 10.3884 9.818 9.99397 9.42362C9.59959 9.02923 9.09711 8.76065 8.55009 8.65184C8.00306 8.54303 7.43605 8.59888 6.92076 8.81232C6.40548 9.02576 5.96505 9.3872 5.65519 9.85095C5.34532 10.3147 5.17993 10.8599 5.17993 11.4177C5.17993 12.1656 5.47704 12.8828 6.00589 13.4117C6.53474 13.9406 7.25202 14.2377 7.99993 14.2377V14.2377ZM7.99993 9.66432C8.34671 9.66432 8.6857 9.76716 8.97403 9.95981C9.26237 10.1525 9.4871 10.4263 9.6198 10.7467C9.75251 11.0671 9.78723 11.4196 9.71958 11.7597C9.65192 12.0998 9.48493 12.4122 9.23973 12.6575C8.99452 12.9027 8.6821 13.0696 8.34199 13.1373C8.00188 13.205 7.64934 13.1702 7.32896 13.0375C7.00858 12.9048 6.73475 12.6801 6.54209 12.3918C6.34943 12.1034 6.2466 11.7644 6.2466 11.4177C6.2466 10.9526 6.43132 10.5067 6.76014 10.1779C7.08895 9.84905 7.53492 9.66432 7.99993 9.66432Z"
        fill="black"
      />
      <path
        d="M10.6666 2.23958C10.6666 1.88596 10.5261 1.54682 10.2761 1.29677C10.026 1.04673 9.68687 0.90625 9.33325 0.90625H6.66659C6.31296 0.90625 5.97383 1.04673 5.72378 1.29677C5.47373 1.54682 5.33325 1.88596 5.33325 2.23958V6.90625H10.6666V2.23958ZM9.33325 5.57292H6.66659V2.23958H9.33325V5.57292Z"
        fill="black"
      />
      <path
        d="M13.3334 19.5727V18.0393C13.3354 17.8617 13.2739 17.6891 13.1601 17.5527C12.5131 16.8319 11.7155 16.2624 10.8237 15.8845C9.93196 15.5066 8.96795 15.3296 8.0001 15.366C7.03071 15.332 6.06574 15.5122 5.17388 15.8936C4.28202 16.275 3.48519 16.8483 2.8401 17.5727C2.72677 17.7094 2.66538 17.8818 2.66677 18.0593V19.5727H3.73344V18.1527C4.28044 17.5778 4.9443 17.1268 5.68029 16.8301C6.41629 16.5334 7.20732 16.3979 8.0001 16.4327C8.79339 16.3987 9.58473 16.5353 10.3207 16.8332C11.0568 17.131 11.7204 17.5832 12.2668 18.1593V19.5727H13.3334Z"
        fill="black"
      />
      <path
        d="M14.6667 3.57227H12V4.9056H14.6667V20.9056H1.33333V4.9056H4V3.57227H1.33333C0.979711 3.57227 0.640573 3.71274 0.390524 3.96279C0.140476 4.21284 0 4.55198 0 4.9056V20.9056C0 21.2592 0.140476 21.5984 0.390524 21.8484C0.640573 22.0985 0.979711 22.2389 1.33333 22.2389H14.6667C15.0203 22.2389 15.3594 22.0985 15.6095 21.8484C15.8595 21.5984 16 21.2592 16 20.9056V4.9056C16 4.55198 15.8595 4.21284 15.6095 3.96279C15.3594 3.71274 15.0203 3.57227 14.6667 3.57227V3.57227Z"
        fill="black"
      />
    </svg>
  );
}
