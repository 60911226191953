import { useQuery } from '@tanstack/react-query';
import * as R from 'remeda';

import { oprApi } from '~/shared/services/api';

type GetBandGroupsAPIResponse = Array<string>;

// TODO: find a more elegant way to do this ordering
// Maybe transform this in a global thing
const orderReference = [
  'CEO',
  'SLT',
  '0',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
  'XII',
  'CAN-CR-A',
  'CAN-CR-B',
  'CAN-CR-C',
  'CAN-CR-D',
  'CAN-CR-E',
  'CAN-CR-F',
  'CAN-CR-G',
  'CAN-CR-H',
  'USA-ADVSR',
  'USA - CRA 1',
  'USA - CRA 2',
  'USA - CRA 3',
  'USA - CRA 4',
  'USA - CRA 5',
  'USA - CRA 6',
  'USA - CRA 7',
  'USA - CRB 1',
  'USA - CRB 2',
  'USA - CRB 3',
  'USA - CRB 4',
  'USA - CRB 5',
  'USA - CRB 6',
  'USA - CRB 7',
  'USA - CRB 8',
  'USA - CRB 9',
  'USA - CRB 10',
  'USA - CRC 1',
  'USA - CRC 2',
  'USA - CRC 3',
  'USA - CRC 4',
  'USA - CRC 5',
  'USA - CRC 6',
  'USA - CRC 7',
  'USA - CRC 8',
  'USA - CRC 9',
  'USA - CRD 1',
  'USA - CRD 2',
  'USA - CRD 3',
  'USA - CRD 4',
  'USA - CRD 5',
  'USA - CRD 6',
  'USA - CRD 7',
  'USA - CRD 8',
  'USA - CRD 9',
  'USA - CRD 10',
  'USA - CRD 11',
  'USA - CRE 1',
  'USA - CRE 2',
  'USA - CRE 3',
  'USA - CRE 4',
  'USA - CRE 5',
  'USA - CRE 6',
  'USA - CRE 7',
  'USA - CRF 1',
  'USA - CRF 2',
  'USA - CRF 3',
  'USA - CRF 4',
  'USA - CRF 5',
  'USA - CRF 6',
  'USA - CRF 7',
  'USA - CRF 8',
  'USA - CRF 9',
  'USA - CRF 10',
  'USA - CRG 1',
  'USA - CRG 2',
  'USA - CRG 3',
  'USA - CRG 4',
  'USA - CRG 5',
  'USA - CRG 6',
  'USA - CRG 7',
  'USA - CRH 2',
  'USA - CRH 3',
  'USA - CRH 4',
  'USA - CRH 5',
  'USA - CRI 1',
] as const;

function getBandGroups() {
  return oprApi.get<GetBandGroupsAPIResponse>('employees/band-groups');
}

export function useGetBandGroups() {
  return useQuery({
    queryKey: ['useGetBandGroups'],
    queryFn: getBandGroups,
    select: ({ data }) => {
      const valuesWithReference = data.filter(value =>
        orderReference.includes(value as (typeof orderReference)[number])
      );
      const valuesWithoutReference = R.difference(data, valuesWithReference);

      const orderedValues = valuesWithReference.sort(
        (a, b) =>
          orderReference.indexOf(a as (typeof orderReference)[number]) -
          orderReference.indexOf(b as (typeof orderReference)[number])
      );

      orderedValues.push(...valuesWithoutReference);

      return orderedValues;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
