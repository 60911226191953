import * as React from 'react';

type PersonFeedBackProps = {
  size?: number;
  color?: string;
};

export function PersonFeedback({
  size = 24,
  color = '#ffffff',
}: PersonFeedBackProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <path
        fill="currentColor"
        d="M9.5 1A1.5 1.5 0 008 2.5v2a1.5 1.5 0 001 1.414V7a.5.5 0 00.82.384L11.48 6h2.02A1.5 1.5 0 0015 4.5v-2A1.5 1.5 0 0013.5 1h-4zM9 2.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-2.2a.5.5 0 00-.32.116l-.98.816V5.5a.5.5 0 00-.5-.5.5.5 0 01-.5-.5v-2zM3 6a2 2 0 114 0 2 2 0 01-4 0zm2-1a1 1 0 100 2 1 1 0 000-2zM2.5 9h5A1.5 1.5 0 019 10.5c0 1.116-.459 2.01-1.212 2.615C7.047 13.71 6.053 14 5 14c-1.053 0-2.047-.29-2.788-.885C1.46 12.51 1 11.616 1 10.5A1.5 1.5 0 012.5 9zm5 1h-5a.5.5 0 00-.5.5c0 .817.325 1.423.838 1.835C3.364 12.757 4.12 13 5 13c.88 0 1.636-.243 2.162-.665.513-.412.838-1.018.838-1.835a.5.5 0 00-.5-.5z"
      />
    </svg>
  );
}
