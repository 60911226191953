import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as R from 'remeda';

import { useGetRelatedQueries } from '~/shared/hooks';
import { oprApi } from '~/shared/services/api';

import {
  GET_EMPLOYEES_ELIGIBILITY_QUERY_KEY,
  GetEmployeesEligibilityAPIResponse,
} from './useGetEmployeesEligibility';

type PutEmployeesEligibilityPayload = {
  employeesIds: string[];
  isEligible: boolean;
};

async function putEmployeesEligibility(
  payload: PutEmployeesEligibilityPayload
) {
  const idsChunks = R.chunk(payload.employeesIds, 1000);
  const requestsChunks = R.chunk(idsChunks, 3);

  for (let i = 0; i < requestsChunks.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    await Promise.all(
      requestsChunks[i].map(chunk =>
        oprApi.put('eligibility', {
          employeesIds: chunk,
          isEligible: payload.isEligible,
        })
      )
    );
  }

  return payload;
}

type UsePutEmployeesEligibilityOptions = {
  onSuccess?: (data: PutEmployeesEligibilityPayload) => void;
  onError?: (error: Error) => void;
  optimisticUpdate?: boolean;
};

export function usePutEmployeesEligibility({
  onSuccess,
  onError,
  optimisticUpdate,
}: UsePutEmployeesEligibilityOptions = {}) {
  const queryClient = useQueryClient();
  const { getRelatedQueries } = useGetRelatedQueries();

  return useMutation({
    mutationFn: putEmployeesEligibility,
    onSuccess(data) {
      if (optimisticUpdate) {
        const sentPayload = data;

        getRelatedQueries([GET_EMPLOYEES_ELIGIBILITY_QUERY_KEY]).forEach(
          cache => {
            const isFilteredByEligibles =
              (cache.queryKey[1] as Record<string, unknown>).eligibilityView ===
              'eligible';
            const isFilteredByNotEligibles =
              (cache.queryKey[1] as Record<string, unknown>).eligibilityView ===
              'ineligible';

            queryClient.setQueryData(
              cache.queryKey,
              (old?: GetEmployeesEligibilityAPIResponse) =>
                old
                  ? {
                      ...old,
                      countEligible:
                        sentPayload.employeesIds.length *
                          (sentPayload.isEligible ? 1 : -1) +
                        old.countEligible,
                      countNotEligible:
                        sentPayload.employeesIds.length *
                          (sentPayload.isEligible ? -1 : 1) +
                        old.countNotEligible,
                      data: old.data
                        .filter(employee => {
                          if (
                            isFilteredByEligibles &&
                            !sentPayload.isEligible &&
                            sentPayload.employeesIds.includes(employee.id)
                          ) {
                            return false;
                          }

                          if (
                            isFilteredByNotEligibles &&
                            sentPayload.isEligible &&
                            sentPayload.employeesIds.includes(employee.id)
                          ) {
                            return false;
                          }

                          return true;
                        })
                        .map(employee =>
                          sentPayload.employeesIds.includes(employee.id)
                            ? {
                                ...employee,
                                eligible: sentPayload.isEligible,
                              }
                            : employee
                        ),
                    }
                  : undefined
            );

            if (isFilteredByEligibles || isFilteredByNotEligibles) {
              queryClient.removeQueries({
                queryKey: cache.queryKey,
              });
            }
          }
        );
      }

      onSuccess?.(data);
    },
    onError,
  });
}
