import { ButtonV2 } from '@ghq-abi/design-system';
import { ArrowsFullscreenIcon } from '@ghq-abi/design-system-icons';
import { useTranslate } from '@tolgee/react';

import { useFullscreen } from '~/app/contexts/FullscreenContext';

export function FullScreenActivate({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslate('web-app');

  const fullscreen = useFullscreen();

  return (
    <ButtonV2
      leftIcon={<ArrowsFullscreenIcon />}
      variant="secondary"
      size="md"
      onClick={fullscreen.activate}
      title={t('actions.activate', { t: t('common.fullscreen') })}
      disabled={disabled}
    >
      {t('common.fullscreen')}
    </ButtonV2>
  );
}
