type AvatarProps = {
  color?: string;
  size?: number;
};

export function AvatarCircle({ color = '#191F2E', size = 24 }: AvatarProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      color={color}
    >
      <path
        d="M16.5 9C16.5 11.4853 14.4853 13.5 12 13.5C9.51472 13.5 7.5 11.4853 7.5 9C7.5 6.51472 9.51472 4.5 12 4.5C14.4853 4.5 16.5 6.51472 16.5 9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 14.4801 2.35988 16.7594 3.79779 18.556C4.86439 16.8379 7.20696 15 12 15C16.7931 15 19.1356 16.8379 20.2022 18.5561C21.6401 16.7594 22.5 14.4801 22.5 12C22.5 6.20101 17.799 1.5 12 1.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
