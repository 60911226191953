type SortBoolAscProps = {
  size?: number;
  color?: string;
};

export function SortBoolAsc({
  size = 17,
  color = '#6f6f6f',
}: SortBoolAscProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <path
        fill="currentColor"
        d="M12.784 11.833h2L12.118 14.5 9.45 11.833h2V2.5h1.333v9.333zM6.118 9.167H3.45c-.74 0-1.333.593-1.333 1.333v2.667c0 .74.593 1.333 1.333 1.333h2.667c.74 0 1.333-.593 1.333-1.333V10.5c0-.74-.593-1.333-1.333-1.333zM4.298 13.5L2.61 11.8l.713-.7.98.993 1.947-1.926.707.7-2.66 2.633zm1.82-11H3.45c-.74 0-1.333.593-1.333 1.333V6.5c0 .74.593 1.333 1.333 1.333h2.667c.74 0 1.333-.593 1.333-1.333V3.833c0-.74-.593-1.333-1.333-1.333zm0 4H3.45V3.833h2.667V6.5z"
      />
    </svg>
  );
}
