import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslate } from '@tolgee/react';

interface UseOnLeavePageConfirmationProps {
  hasUnsavedChanges: boolean;
}

export const useOnLeavePageConfirmation = ({
  hasUnsavedChanges: unsavedChanges,
}: UseOnLeavePageConfirmationProps) => {
  const router = useRouter();
  const { t } = useTranslate('web-app-succession');

  const [shouldOpenDialog, setShouldOpenDialog] = useState(false);
  const [nextUrl, setNextUrl] = useState('');

  //* Work around to don't show the progress bar when we ABORT the route navigation
  function setNProgressBarVisibility(value: 'initial' | 'none') {
    const ID_OF_NEXT_PROGRESS_BAR = 'nprogress';

    const nProgressBar = window.document.getElementById(
      ID_OF_NEXT_PROGRESS_BAR
    );

    nProgressBar?.style.setProperty('display', value);
  }

  async function onLeaveConfirm() {
    setNProgressBarVisibility('initial');
    await router.push(nextUrl);
    setShouldOpenDialog(false);
    setNextUrl('');
  }

  async function onLeaveCancel() {
    await router.push(nextUrl);
    setShouldOpenDialog(false);
    setNextUrl('');
  }

  function onCloseLeaveDialog() {
    setShouldOpenDialog(false);
    setNextUrl('');
  }

  useEffect(() => {
    if (unsavedChanges && !nextUrl) {
      const routeChangeStart = (url: string) => {
        setShouldOpenDialog(true);
        setNextUrl(url);

        if (nextUrl) {
          return url;
        }

        setNProgressBarVisibility('none');
        router.events.on('routeChangeError', () => url);
        throw new Error(t('abort_router_error'));
      };

      router.events.on('routeChangeStart', routeChangeStart);

      return () => {
        router.events.off('routeChangeStart', routeChangeStart);
      };
    }

    return undefined;
  }, [unsavedChanges, router, t, nextUrl]);

  return {
    onLeaveConfirm,
    onLeaveCancel,
    onCloseLeaveDialog,
    shouldOpenDialog,
  };
};
