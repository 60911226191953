type DocumentProps = {
  size?: number;
  color?: string;
};

export function Document({ size = 20, color = '#000' }: DocumentProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <path
        fill="currentColor"
        d="M16.5 18.5C16.5 19.0304 16.2893 19.5391 15.9142 19.9142C15.5391 20.2893 15.0304 20.5 14.5 20.5H2.5C1.96957 20.5 1.46086 20.2893 1.08579 19.9142C0.710714 19.5391 0.5 19.0304 0.5 18.5V2.5C0.5 1.96957 0.710714 1.46086 1.08579 1.08579C1.46086 0.710714 1.96957 0.5 2.5 0.5H8.672C8.69946 0.500469 8.72686 0.502808 8.754 0.507C8.77353 0.510376 8.79322 0.512712 8.813 0.514C9.02711 0.525854 9.23786 0.572463 9.437 0.652C9.49146 0.674932 9.5441 0.705169 9.59579 0.734857L9.603 0.739L9.653 0.768L9.701 0.792C9.72866 0.804779 9.75539 0.819482 9.781 0.836C9.85802 0.89093 9.9312 0.951071 10 1.016C10.0116 1.02644 10.0236 1.03645 10.036 1.046C10.0532 1.05827 10.0695 1.07163 10.085 1.086L15.914 6.914C16.2891 7.28899 16.4999 7.79761 16.5 8.328V18.5ZM2.5 19H14.5C14.6326 19 14.7598 18.9473 14.8536 18.8536C14.9473 18.7598 15 18.6326 15 18.5V8.5H10.5C9.96957 8.5 9.46086 8.28929 9.08579 7.91421C8.71071 7.53914 8.5 7.03043 8.5 6.5V2H2.5C2.36739 2 2.14645 2.14645 2.14645 2.14645C2.14645 2.14645 2 2.36739 2 2.5V18.5C2 18.6326 2.05268 18.7598 2.14645 18.8536C2.24021 18.9473 2.36739 19 2.5 19ZM10.5 7H13.878L10 3.121V6.5C10 6.63261 10.0527 6.75979 10.1464 6.85355C10.2402 6.94732 10.3674 7 10.5 7Z"
      />
    </svg>
  );
}
