type UserCheckProps = {
  size?: number;
  color?: string;
};

export function UserCheck({ size = 24, color = '#325a6d' }: UserCheckProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color}
    >
      <path
        stroke="currentColor"
        strokeWidth="2"
        d="M12 12a5 5 0 100-10 5 5 0 000 10z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 22H5.266a2 2 0 01-1.985-2.248l.39-3.124A3 3 0 016.65 14H7m9 2l2.25 2L22 14"
      />
    </svg>
  );
}
