type ChartProps = {
  size?: number;
  color?: string;
};

export function Chart({ size = 18, color = '#000' }: ChartProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      color={color}
    >
      <path
        d="M1.5 0.822266H0.25V17.0723C0.25 17.4038 0.381696 17.7217 0.616116 17.9561C0.850537 18.1906 1.16848 18.3223 1.5 18.3223H17.75V17.0723H1.5V0.822266Z"
        fill="currentColor"
      />
      <path
        d="M17.75 5.19727H13.375V6.44727H15.6187L10.875 11.191L8.19375 8.50352C8.13565 8.44494 8.06652 8.39844 7.99036 8.36671C7.9142 8.33498 7.83251 8.31864 7.75 8.31864C7.66749 8.31864 7.5858 8.33498 7.50964 8.36671C7.43348 8.39844 7.36435 8.44494 7.30625 8.50352L2.75 13.066L3.63125 13.9473L7.75 9.82852L10.4313 12.516C10.4894 12.5746 10.5585 12.6211 10.6346 12.6528C10.7108 12.6846 10.7925 12.7009 10.875 12.7009C10.9575 12.7009 11.0392 12.6846 11.1154 12.6528C11.1915 12.6211 11.2606 12.5746 11.3187 12.516L16.5 7.32852V9.57227H17.75V5.19727Z"
        fill="currentColor"
      />
    </svg>
  );
}
