type TopThirdProps = {
  size?: number;
  color?: string;
};

export function TopThird({ size = 16, color = '#000' }: TopThirdProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
    >
      <path
        fill={color}
        d="M3 1.5a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5zM11 4v9.5a1 1 0 01-1 1H6a1 1 0 01-1-1V4a1 1 0 011-1h4a1 1 0 011 1zm-1 0H6v9.5h4V4z"
      />
    </svg>
  );
}
