import * as React from 'react';

type ContactProps = { size?: number; color?: string };

export function Contact({ size = 16, color = '#191F2E' }: ContactProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 15"
      fill="none"
      color={color}
    >
      <path
        d="M11.25 9.18126C12.8033 9.18126 14.0625 7.92206 14.0625 6.36876C14.0625 4.81546 12.8033 3.55626 11.25 3.55626C9.69672 3.55626 8.43752 4.81546 8.43752 6.36876C8.43752 7.92206 9.69672 9.18126 11.25 9.18126Z"
        fill="currentColor"
      />
      <path
        d="M2.25 0.125C1.00736 0.125 0 1.13236 0 2.375V12.5C0 13.7426 1.00736 14.75 2.25 14.75H15.75C16.9926 14.75 18 13.7426 18 12.5V2.375C18 1.13236 16.9926 0.125 15.75 0.125H2.25ZM1.125 2.375C1.125 1.75368 1.62868 1.25 2.25 1.25H4.5V3.5H1.125V2.375ZM5.625 13.625V1.25H15.75C16.3713 1.25 16.875 1.75368 16.875 2.375V12.5C16.875 12.8208 16.7407 13.1103 16.5252 13.3152C15.9204 11.9306 14.4255 10.25 11.25 10.25C7.83318 10.25 6.36214 12.1958 5.85194 13.625H5.625ZM1.125 11.375H4.5V13.625H2.25C1.62868 13.625 1.125 13.1213 1.125 12.5V11.375ZM4.5 10.25H1.125V8H4.5V10.25ZM4.5 6.875H1.125V4.625H4.5V6.875Z"
        fill="currentColor"
      />
    </svg>
  );
}
