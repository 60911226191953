import React from 'react';
import { signIn, useSession } from 'next-auth/react';
import { LanguageDropdown } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';
import { LanguagesEnum } from 'abi-opr-common-types';

import { useToast } from '~/app/contexts/ToastContext';
import { useUpdatePreferredLanguage } from '~/entities/Translations';
import { CredentialProviders } from '~/shared/auth/types/providers';

type LanguageDropdownProps = {
  currentLanguage: keyof typeof LanguagesEnum;
};

export const LanguageDropdownHeader = ({
  currentLanguage,
}: LanguageDropdownProps) => {
  const { data: session } = useSession();
  const { t } = useTranslate('web-app');
  const toast = useToast();

  const userLanguage = currentLanguage?.replaceAll(
    '_',
    '-'
  ) as keyof typeof LanguagesEnum;

  const userLanguageLabel = t(`languages.${userLanguage}`);

  const updatePreferredLanguage = useUpdatePreferredLanguage({
    onSuccess({ data }) {
      toast.add({
        description: t('translations.preferred_language.success'),
        type: 'success',
      });

      const currentUser = session?.user;
      if (currentUser === undefined) return;

      currentUser.lcl = data.oprPreferredLanguage;

      signIn(CredentialProviders.UPDATE_LANGUAGE, {
        user: JSON.stringify(currentUser),
      });
    },
    onError() {
      toast.add({
        description: t('translations.preferred_language.error'),
        type: 'error',
      });
    },
  });

  function handleLanguageChange(value: string) {
    toast.add({
      description: t('translations.preferred_language.updating'),
      type: 'neutral',
    });
    updatePreferredLanguage.mutate({ preferredLanguage: value });
  }

  const languageOptions = Object.keys(LanguagesEnum).map(lang => ({
    label: t(`languages.${lang}`),
    onSelect: () => handleLanguageChange(lang),
  }));

  const currentLanguageLabel =
    languageOptions.find(option => option.label === userLanguageLabel)?.label ||
    userLanguageLabel;

  return (
    <LanguageDropdown value={currentLanguageLabel} items={languageOptions} />
  );
};
