type VoteProps = {
  size?: number;
  color?: string;
};

export function Vote({ size = 16, color = '#2e2c34' }: VoteProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M13.665 10.334H2.333v3.333h11.332v-3.333zm.965-.686l.014.042.015.066.007.067v4.344a.5.5 0 01-.433.495l-.067.005H1.834a.5.5 0 01-.496-.432l-.005-.068V9.834l.002-.035.006-.057a.498.498 0 01.032-.102l1.84-4.013a.5.5 0 01.382-.287l.072-.004 1.693-.001-.527.913-.044.087h-.802L2.612 9.334H13.38L12.03 6.436l.575-.998a.491.491 0 01.114.123l.036.063 1.876 4.024zM8.91 1.371l.062.03 3.458 2.002a.501.501 0 01.213.622l-.03.061-1.871 3.248h.758a.5.5 0 01.068.996l-.068.004h-1.335v.002H7.447l-.002-.002H4.5a.5.5 0 01-.068-.995l.068-.005h1.215l-.12-.07a.501.501 0 01-.213-.622l.03-.062L8.29 1.583a.499.499 0 01.62-.213zm-.007 1.147L6.526 6.647l1.186.687h1.877l1.91-3.316-2.594-1.502-.002.002z"
      />
    </svg>
  );
}
