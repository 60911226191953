import * as React from 'react';
import { Toast } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { TOAST_DURATION } from '~/shared/constants/toasts';
import { randomUUID } from '~/shared/utils/randomUUID';

type ToastContextData = {
  add: (message: Omit<ToastMessage, 'id'> | string) => void;
};

const ToastContext = React.createContext({} as ToastContextData);

type ToastProviderProps = {
  children: React.ReactNode;
};

export type ToastMessage = {
  id: string;
  title?: string;
  description?: string;
  type?: React.ComponentProps<typeof Toast>['variant'];
  children?: React.ReactNode;
  icon?: React.ReactNode;
};

export function ToastProvider({ children }: ToastProviderProps) {
  const { t } = useTranslate('web-app');

  const [toasts, setToasts] = React.useState<ToastMessage[]>([]);

  const add = React.useCallback(
    (message: Omit<ToastMessage, 'id'> | string) => {
      const toast =
        typeof message === 'string' ? { description: message } : message;

      setToasts(state => [...state, { id: randomUUID(), ...toast }]);
    },
    []
  );

  function handleToastOpenChange(open: boolean, toastId: string) {
    const isClosing = !open;

    if (isClosing) {
      setTimeout(
        () => setToasts(state => state.filter(item => item.id !== toastId)),
        500
      );
    }
  }

  const providerValue = React.useMemo(() => ({ add }), [add]);

  return (
    <Toast.Provider
      duration={TOAST_DURATION}
      swipeDirection="right"
      label={t('common.notification')}
    >
      <ToastContext.Provider value={providerValue}>
        {children}
      </ToastContext.Provider>

      {toasts.map(toast => {
        let toastTitle = toast.title ?? '';
        if (!toastTitle && toast.type === 'error') {
          toastTitle = t('common.error');
        }
        return (
          <Toast
            key={toast.id}
            title={toastTitle}
            description={toast.description}
            variant={toast.type}
            onOpenChange={open => handleToastOpenChange(open, toast.id)}
            closeAriaLabel={t('common.close_toast')}
            icon={toast.icon}
            isDetailed
          >
            {toast.children}
          </Toast>
        );
      })}

      <Toast.Viewport
        label={`${t('common.notifications')} ({hotkey})`}
        css={{ top: 'unset', right: '0', bottom: 0, transform: 'unset' }}
      />
    </Toast.Provider>
  );
}

export function useToast() {
  const context = React.useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}
