type MediumThirdProps = {
  size?: number;
  color?: string;
};

export function MediumThird({ size = 16, color = '#000' }: MediumThirdProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
    >
      <path
        fill={color}
        d="M13 7.5h-2V3a1 1 0 00-1-1H6a1 1 0 00-1 1v4.5H3a.5.5 0 100 1h2V13a1 1 0 001 1h4a1 1 0 001-1V8.5h2a.5.5 0 000-1zM10 13H6V3h4v10z"
      />
    </svg>
  );
}
