import { useQuery } from '@tanstack/react-query';

import { useUser } from '~/shared/auth/useUser';
import { peoplePlatformApi } from '~/shared/services/api';

type GetZonesAPIResponse = Array<string>;

function getZones() {
  return peoplePlatformApi.get<GetZonesAPIResponse>('zones');
}

type UseGetZonesOptions = {
  enabled?: boolean;
  filterByManagedZones?: boolean;
};

export function useGetZones({
  enabled = true,
  filterByManagedZones,
}: UseGetZonesOptions = {}) {
  const user = useUser();

  return useQuery({
    queryKey: ['useGetZones'],
    queryFn: getZones,
    select: ({ data }) =>
      data
        .sort((a, b) => a.localeCompare(b))
        .filter(Boolean)
        .filter(zone => {
          if (
            user.roles?.isZoneAdmin &&
            !user.roles.isGlobalAdmin &&
            filterByManagedZones
          ) {
            return user.managedZones?.includes(zone);
          }

          return true;
        }),
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
  });
}
