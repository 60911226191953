import { FiXCircle } from 'react-icons/fi';
import Link from 'next/link';
import { Link as LinkDS, Toast } from '@ghq-abi/design-system';

import type { ToastMessage } from '~/app/contexts/ToastContext';

import { TFunctionType } from '../types/utils';

export const TOAST_DURATION = 3500;

export const TOAST_ASYNC_DOWNLOAD_REQUEST_SUCCESSFUL = (
  t: TFunctionType
): Omit<ToastMessage, 'id'> => {
  return {
    title: t('common.request_successful'),
    description: t('common.document_warning'),
    children: (
      <Link href="/documents" passHref legacyBehavior>
        <LinkDS href="#!" css={{ textDecoration: 'none' }}>
          <Toast.Close
            css={{
              position: 'relative',
              color: 'inherit',
              fontSize: 'inherit',
            }}
            aria-label={t('common.go_to_documents')}
          >
            {t('common.go_to_documents')}
          </Toast.Close>
        </LinkDS>
      </Link>
    ),
    type: 'warning',
  };
};

export const TOAST_ASYNC_DOWNLOAD_WARNING = (
  t: TFunctionType
): Omit<ToastMessage, 'id'> => {
  return {
    description: t('common.async_download_warning'),
    type: 'neutral',
  };
};

export const TOAST_DOWNLOAD_WARNING = (
  t: TFunctionType
): Omit<ToastMessage, 'id'> => {
  return {
    description: t('common.download_warning'),
    type: 'neutral',
  };
};

export const TOAST_DOWNLOAD_SUCCESSFUL = (
  t: TFunctionType
): Omit<ToastMessage, 'id'> => {
  return {
    description: t('common.download_successful'),
    type: 'success',
  };
};

export const TOAST_DOWNLOAD_NO_FILTER_WARNING = (
  t: TFunctionType
): Omit<ToastMessage, 'id'> => {
  return {
    title: t('common.download_no_filter_warning_title'),
    description: t('common.download_no_filter_warning'),
    icon: <FiXCircle size="24" />,
    type: 'warning',
  };
};
