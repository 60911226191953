/* eslint-disable */
import type { Session } from 'next-auth';
import { getSession, signIn, signOut } from 'next-auth/react';
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  CreateAxiosDefaults,
  HttpStatusCode,
} from 'axios';
import { isPast } from 'date-fns';

import { CredentialProviders } from '../auth/types/providers';
import { isClient, isServer, isTest } from '../utils/environments';

type FailedRequest = {
  onSuccess: (token?: string) => void;
  onFailure: (error: AxiosError) => void;
};

const MAX_RETRY_COUNT = 3;

class ApiBuilder {
  private static instance: ApiBuilder;

  private session: Session | null = null;

  private isRefreshing = false;
  private isLinkingSession = false;
  private failedRequestsQueue: FailedRequest[] = [];
  private retryCount = 0;

  private constructor() {
    if (isClient() && !isTest()) {
      getSession().then(session => {
        this.session = session;
      });
    }
  }

  public static getInstance(): ApiBuilder {
    if (!ApiBuilder.instance) {
      ApiBuilder.instance = new ApiBuilder();
    }

    return ApiBuilder.instance;
  }

  private getAuthToken() {
    return this.session
      ? this.session.user.px?.tk ?? this.session.user.tk
      : null;
  }

  public mount(config: CreateAxiosDefaults = {}) {
    const api = axios.create({
      ...config,
      withCredentials: true,
      paramsSerializer: { indexes: null },
    });

    if (!isTest()) {
      api.interceptors.request.use(async requestConfig => {
        if (isClient()) {
          const isSessionInvalid =
            !this.session || isPast(new Date(this.session.expires));

          if (isSessionInvalid && isClient()) {
            this.session = (await getSession()) ?? this.session;
          }

          if (this.session) {
            requestConfig.headers = (requestConfig.headers ??
              {}) as AxiosHeaders;

            const hasAlreadySetAuthorization =
              !!requestConfig.headers.getAuthorization();

            if (!hasAlreadySetAuthorization) {
              requestConfig.headers.setAuthorization(
                `Bearer ${this.getAuthToken()}`
              );
            }
          }
        }

        return requestConfig;
      });

      api.interceptors.response.use(
        response => response,
        (error: AxiosError) => this.handleRefreshToken(api, error)
      );
    }

    return api;
  }

  private handleRefreshToken(api: AxiosInstance, error: AxiosError) {
    const originalConfig = error.config;

    if (error.response?.status === HttpStatusCode.Forbidden && isClient()) {
      if (!this.isLinkingSession) {
        this.isLinkingSession = true;
        return new Promise((resolve, reject) => {
          signIn(CredentialProviders.AZURE_AD, { redirect: false })
            .then(() => resolve(getSession({ triggerEvent: true })))
            .catch(err => reject(err))
            .finally(() => {
              this.isLinkingSession = false;
            });
        });
      }
    }

    if (error.response?.status === HttpStatusCode.Unauthorized && isClient()) {
      if (
        !this.isRefreshing &&
        this.retryCount < MAX_RETRY_COUNT &&
        !this.isLinkingSession
      ) {
        this.isRefreshing = true;

        signIn('refresh-token', {
          user: this.session ? JSON.stringify(this.session.user) : null,
          redirect: false,
        })
          .then(async refreshResponse => {
            if (refreshResponse && !refreshResponse.ok) {
              throw new Error('Invalid response');
            }

            this.retryCount = 0;

            this.session = await getSession();

            originalConfig?.headers.setAuthorization('');

            this.failedRequestsQueue.forEach(request => request.onSuccess());
            this.failedRequestsQueue = [];
          })
          .catch(err => {
            this.retryCount += 1;

            this.failedRequestsQueue.forEach(request =>
              request.onFailure(err as AxiosError)
            );
            this.failedRequestsQueue = [];
          })
          .finally(() => {
            this.isRefreshing = false;
          });
      }

      if (this.retryCount >= MAX_RETRY_COUNT) {
        signOut({ redirect: false });
      }

      return new Promise((resolve, reject) => {
        this.failedRequestsQueue.push({
          onSuccess(token?: string) {
            if (!originalConfig) {
              reject(new Error('Original config not found'));
              return;
            }
            originalConfig.headers.setAuthorization('');

            if (originalConfig.headers && token) {
              originalConfig.headers.setAuthorization(`Bearer ${token}`);
            }

            resolve(api(originalConfig));
          },
          onFailure(err) {
            reject(err);
          },
        });
      });
    }

    return Promise.reject(error);
  }
}

export const oprApi = ApiBuilder.getInstance().mount({
  baseURL: process.env.NEXT_PUBLIC_OPR_API_URL,
});

export const translationApi = ApiBuilder.getInstance().mount({
  baseURL: process.env.NEXT_PUBLIC_TRANSLATION_API_URL,
});

export const peoplePlatformApi = ApiBuilder.getInstance().mount({
  baseURL: process.env.NEXT_PUBLIC_PEOPLE_PLATFORM_API_URL,
});

export const authApiPublic = ApiBuilder.getInstance().mount({
  baseURL: process.env.NEXT_PUBLIC_AUTH_API_URL,
});

export const authApiInternal = ApiBuilder.getInstance().mount({
  baseURL: process.env.NEXT_PUBLIC_AUTH_API_URL_INTERNAL,
});

export const photoApi = ApiBuilder.getInstance().mount({
  baseURL: process.env.NEXT_PUBLIC_PEOPLE_PLATFORM_PHOTO_API_URL,
  responseType: 'blob',
});

// eslint-disable-next-line func-names
export const authApi = function () {
  if (isServer()) {
    return authApiInternal;
  }
  return authApiPublic;
};
