import { StyledSpan } from './styles';

type OprVoteProps = React.ComponentProps<typeof StyledSpan> & {
  voteText?: string;
};

export function OprVote({ vote, voteText, ...props }: OprVoteProps) {
  return (
    <StyledSpan {...props} vote={vote}>
      {vote && `${voteText ?? vote}`}
      {!vote && voteText && `${voteText}`}
    </StyledSpan>
  );
}
